<template>
  <div class="timer-list admin-page">
    <div class="admin-page__header">
      <h1>Ajaarvestuse kanded</h1>
    </div>

    <div class="timer-list__excel">
      <button
        class="button"
        @click="exportTimers"
        :disabled="isProcessingExport"
        title="Ekspordi Excelisse"
      >
        <i v-if="isProcessingExport" class="spinner fal fa-spinner"></i>
        <span v-else><i class="fal fa-file-export"></i> Eksport</span>
      </button>
    </div>

    <datatable
      :options="tableOptions"
      :reloadTrigger="reloadTrigger"
      @componentEventEmitted="onDatatableEvent"
      @searched="onSearchTermChanged"
    />
  </div>
</template>

<script>
import FileSaver from "file-saver";
import Datatable from "../Shared/Datatable";
import TimerListItemActions from "./TimerListItemActions";

export default {
  components: { Datatable },
  data: () => ({
    isProcessingExport: false,
    reloadTrigger: 0,
    searchTerm: "",
    tableOptions: {
      sortedKey: "startDt",
      sortedType: "desc",
      columns: [
        {
          title: "Tööline",
          key: "worker",
        },
        {
          title: "Kuupäev",
          key: "startDt",
          sortedKey: "startDt",
          sortedType: "asc",
          sortable: true,
          dateFormat: true,
          expectFormat: "DD.MM.YYYY",
        },
        {
          title: "Tellimus",
          key: "productionOrder",
        },
        {
          title: "Toode",
          key: "productEan",
        },
        {
          title: "Töökeskus",
          key: "workstation",
        },
        {
          title: "Alguskell",
          key: "startDt",
          dateFormat: true,
          expectFormat: "HH:mm",
        },
        {
          title: "Lõppkellaaeg",
          key: "endDt",
          dateFormat: true,
          expectFormat: "HH:mm",
        },
        {
          title: "Tööaeg (min)",
          key: "minutesWorked",
        },
        {
          title: "Tööaeg (h)",
          key: "hoursWorked",
        },
        {
          title: "Muuda",
          type: "component",
          name: TimerListItemActions,
        },
      ],
      search: true,
      searchPlaceholder: "Otsi tellimuse nr või EAN järgi...",
      source: null,
    },
  }),
  created() {
    this.tableOptions.source = this.appConfig.$apiUrl + this.api.$timerList;
  },
  methods: {
    exportTimers() {
      this.isProcessingExport = true;

      this.$http({
        url: this.api.$excelProductionOrderTimersExport,
        method: "POST",
        data: { term: this.searchTerm },
        responseType: "blob",
      })
        .then((response) => {
          FileSaver.saveAs(
            new Blob([response.data]),
            "Ajaarvestus-" + Date.now() + ".xlsx"
          );
        })
        .catch(() => alert("Eksport ebaõnnestus."))
        .finally(() => {
          this.isProcessingExport = false;
        });
    },
    onDatatableEvent(e) {
      if (e && e.state === "closed") {
        // Edit modal closed, reload table
        this.reloadTrigger++;
      }
    },
    onSearchTermChanged(term) {
      this.searchTerm = term;
    },
  },
};
</script>

<style lang="scss">
.timer-list {
  margin-right: 30px;

  &__excel {
    text-align: right;
  }

  .datatable table {
    width: 100%;
  }

  .search-input {
    width: 265px;
  }
}
</style>
