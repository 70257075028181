<template>
  <div class="app__sidebar">
    <product-list-search v-on:filtered="fetchProductList($event)"></product-list-search>

    <div class="product-list--empty" v-if="!isInitialized">
      Toote leidmiseks sisesta otsingukasti EAN, näidise number, kaubamärk või tootegrupp.
    </div>
    <div class="product-list--loading" v-else-if="isLoading">
      <i class="spinner fal fa-spinner"></i>
    </div>
    <div class="product-list--empty" v-else-if="!products.length">
      Otsingule "{{ searchTerm }}"
      <span v-if="searchPropTitle">({{ searchPropTitle }})</span>
      vastavaid tooteid ei leitud.
    </div>

    <vue-custom-scrollbar :settings="scrollbarSettings" v-else-if="!$isTablet">
      <product-list-items :products="products" />
    </vue-custom-scrollbar>

    <product-list-items v-else :products="products" />
  </div>
</template>

<script>
import VueCustomScrollbar from "vue-custom-scrollbar";
import "vue-custom-scrollbar/dist/vueScrollbar.css";

import ProductListSearch from "./ProductListSearch";
import ProductListItems from "./ProductListItems";

export default {
  components: {
    ProductListSearch,
    ProductListItems,
    VueCustomScrollbar,
  },
  data: () => ({
    isInitialized: false,
    isLoading: false,
    searchTerm: "",
    searchProp: "",
    searchPropTitle: "",
    products: [],
    scrollbarSettings: {
      minScrollbarLength: 50,
    },
  }),

  created() {
    // If product detail view is active,
    // insert that product into the sidebar
    if (this.$route.name === "productDetail") {
      this.fetchProductList({
        term: this.$route.params.id,
        prop: "id",
        propTitle: "ID",
      });
    }
  },

  methods: {
    fetchProductList: function (data) {
      this.isInitialized = true;

      this.searchTerm = data.term || "";
      this.searchProp = data.prop || "";
      this.searchPropTitle = data.propTitle || "";

      this.searchTerm = this.searchTerm.trim();

      if (!this.searchTerm) {
        this.reset();
        return;
      }

      this.isLoading = true;

      let url = this.api.$productListSearch;
      url = url.replace(":term", encodeURIComponent(this.searchTerm));
      url = url.replace(":prop", this.searchProp);

      this.$http
        .get(url)
        .then((result) => {
          this.products = result.data;

          // If a single result was returned,
          // navigate to its detail view
          if (this.products.length === 1) {
            this.$router
              .push({
                name: "productDetail",
                params: { id: this.products[0].id },
              })
              .catch(() => {});
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    reset: function () {
      this.products = [];
      this.searchTerm = "";
      this.searchProp = "";
      this.searchPropTitle = "";
      this.isLoading = false;
      this.isInitialized = false;

      this.$router.push({ name: "productList" }).catch(() => {});
    },
  },
};
</script>
