import { isTablet } from "mobile-device-detect";
import config from "./config";

function getIsTablet() {
    let isUsingTablet = isTablet;

    // Chrome on Galaxy Tab A7 is not recognized as a tablet;
    // double check
    if (!isUsingTablet) {
        const tabletRegex = new RegExp(config.chromeOnGalaxyTabRegex);
        isUsingTablet = tabletRegex.test(navigator.userAgent);
    }

    return isUsingTablet;
}

export const isUsingTablet = getIsTablet();
