<template>
  <div class="datatable">
    <div class="search">
      <search-input
        :placeholder="options.searchPlaceholder"
        maxlength="20"
        @onTermChanged="search"
      />

      <div class="count">
        Kokku <span class="bold">{{ totalCount }}</span> rida
      </div>

      <div class="take">
        Näita
        <select
          class="form-control"
          v-model="showDataAmount"
          v-on:change="selectedDataAmount"
          id="rows"
        >
          <option value="10">10</option>
          <option value="20">20</option>
          <option value="50">50</option>
          <option value="100">100</option>
        </select>
        rida
      </div>
    </div>

    <div class="loader" v-if="isLoading">
      <i class="spinner fal fa-spinner"></i>
    </div>

    <table class="table" v-else>
      <thead>
        <th
          v-for="(column, columnIndex) in options.columns"
          v-bind:style="{ 'text-align': column.textAlign }"
          v-bind:key="columnIndex"
        >
          <span>{{ column.title }}</span>
          <span
            style="float: right"
            v-if="
              requestParams.sortedKey === column.key && requestParams.sortedType === 'asc'
            "
          >
            <a
              href="javascript:"
              v-on:click="sortedKeyValue(column.key, 'desc')"
              title="Sorteeri"
            >
              <i class="fad fa-arrow-up"></i> </a
          ></span>
          <span
            style="float: right"
            v-else-if="
              requestParams.sortedKey === column.key &&
              requestParams.sortedType === 'desc'
            "
          >
            <a href="javascript:" v-on:click="sortedKeyValue(column.key, 'asc')">
              <i class="fad fa-arrow-down"></i> </a
          ></span>
          <span style="float: right" v-else-if="column.sortable">
            <a href="javascript:" v-on:click="sortedKeyValue(column.key, 'desc')">
              <i class="fad fa-sort-down"></i>
            </a>
          </span>
        </th>
      </thead>
      <tbody>
        <template v-if="dataSets.length > 0">
          <tr v-for="(data, dataIndex) in dataSets" :key="dataIndex">
            <td
              v-for="(column, columnIndex) in options.columns"
              v-bind:key="columnIndex"
              v-bind:style="{ 'text-align': column.textAlign }"
            >
              <span v-if="column.type === 'component'">
                <component
                  v-bind:is="column.name"
                  v-bind:row="data"
                  @eventEmitted="onComponentEventEmitted"
                ></component>
              </span>
              <span v-else-if="column.type === 'clickable'">
                <a
                  style="text-decoration: none"
                  v-bind:href="column.source + '/' + data[column.uniqueField]"
                  >{{ data[column.key] }}</a
                >
              </span>
              <span v-else-if="column.dateFormat">
                {{ dateFormat(data[column.key], column.expectFormat) }}
              </span>
              <span v-else>
                {{ data[column.key] }}
              </span>
            </td>
          </tr>
        </template>
        <template v-else>
          <div class="empty-result">Kandeid ei leitud.</div>
        </template>
      </tbody>
    </table>

    <div class="pagination" v-if="totalCount > requestParams.take">
      <a
        href="javascript:"
        v-bind:class="{ disabled: currentPage == 1 }"
        v-on:click="newPage(currentPage - 1)"
        ><i class="fal fa-arrow-left"></i
      ></a>
      <a
        href="javascript:"
        v-on:click="newPage(page)"
        v-bind:class="{ active: currentPage == page }"
        v-for="(page, pageIndex) in pages"
        v-bind:key="pageIndex"
        v-bind:disables="currentPage === page || page === '...'"
        >{{ page }}</a
      >
      <a
        v-on:click="newPage(currentPage + 1)"
        :class="{ disabled: currentPage == totalPage }"
        href="javascript:"
        ><i class="fal fa-arrow-right"></i
      ></a>
    </div>
  </div>
</template>

<script>
const moment = require("moment-timezone");
import _ from "lodash";
import SearchInput from "./SearchInput";

export default {
  components: { SearchInput },
  props: ["options", "reloadTrigger"],
  data: () => ({
    dataSets: [],
    requestParams: {
      take: 10,
      skip: 0,
      search: "",
      sortedType: "asc",
    },
    currentPage: 1,
    pages: [],
    showDataAmount: 10,
    totalPage: 1,
    totalCount: 0,
    isLoading: true,
  }),
  mounted() {
    this.requestParams.sortedKey = this.options.sortedKey || this.options.columns[0].key;
    this.requestParams.sortedType = this.options.sortedType || "asc";
    this.readData();
  },
  methods: {
    dateFormat(date, expectedConfig) {
      if (!date) {
        return "";
      }
      return moment.utc(date).tz("Europe/Tallinn").format(expectedConfig);
    },
    sortedKeyValue(key, type) {
      this.requestParams.sortedKey = key;
      this.requestParams.sortedType = type;
      this.readData();
    },
    readData() {
      this.isLoading = true;

      let instance = this;

      this.$http
        .post(this.options.source, this.requestParams)
        .then((res) => {
          instance.dataSets = res.data.data;
          instance.totalCount = res.data.count;
          instance.totalPage = Math.ceil(
            instance.totalCount / instance.requestParams.take
          );
          instance.pages = instance.pagination(instance.currentPage, instance.totalPage);
        })
        .catch((e) => {
          alert("Tabeli laadimisel esines viga.");
          console.log(e);
        })
        .finally(() => (this.isLoading = false));
    },
    search: _.debounce(function (term) {
      this.requestParams.search = term;
      this.currentPage = 1;
      this.requestParams.skip = 0;
      this.readData();
      this.$emit("searched", term);
    }, 1000),
    selectedDataAmount() {
      this.requestParams.take = this.showDataAmount;
      this.currentPage = 1;
      this.requestParams.skip = 0;
      this.readData();
    },
    newPage(page) {
      if (page != 0 && page <= this.totalPage) {
        this.requestParams.skip = (page - 1) * this.requestParams.take;
        this.currentPage = page;
        this.readData();
      }
    },
    pagination(c, m) {
      var delta = 2,
        range = [],
        rangeWithDots = [],
        l;

      range.push(1);
      for (let i = c - delta; i <= c + delta; i++) {
        if (i < m && i > 1) {
          range.push(i);
        }
      }
      range.push(m);

      for (let i of range) {
        if (l) {
          if (i - l === 2) {
            rangeWithDots.push(l + 1);
          } else if (i - l !== 1) {
            rangeWithDots.push("...");
          }
        }
        rangeWithDots.push(i);
        l = i;
      }

      return rangeWithDots;
    },
    onComponentEventEmitted(e) {
      this.$emit("componentEventEmitted", e);
    },
  },
  watch: {
    reloadTrigger() {
      this.readData();
    },
  },
};
</script>

<style lang="scss">
.datatable {
  font-size: $font-size-text;

  .search,
  .pagination {
    margin: 15px 0;
  }

  .search {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 30px;
    background: lighten($border-color, 8%);

    select {
      margin: 0 5px;
    }
  }

  .loader {
    margin: 45px 0;
    text-align: center;
  }

  .bold {
    font-weight: $font-weight-bold;
  }

  .table {
    border-collapse: collapse;
    width: 100%;
  }

  td,
  th {
    padding: 5px 15px;
    border: $border;
    text-align: left;
    vertical-align: text-top;
  }

  th {
    letter-spacing: 0.5px;
  }

  td:first-child,
  td:nth-child(2),
  td:nth-child(3) {
    white-space: nowrap;
  }

  a {
    color: $color-dark;
  }

  .empty-result {
    margin: 10px 0;
    font-style: italic;
    font-size: 16px;
    text-align: center;
  }

  .pagination {
    text-align: center;

    a {
      padding: 5px 15px;
      margin-right: 5px;
      border: $border;
      background: $background-color;
      transition: all 0.1s ease-in-out;

      &:hover {
        background: $border-color;
      }

      &.active {
        color: $background-color;
        border: 1px solid $color-dark;
        background: $color-dark;
      }
    }

    .disabled {
      cursor: default;
      color: $border-color;

      &:hover {
        background: $background-color;
      }
    }
  }
}
</style>
