<template>
  <div class="app app--timer">
    <timer-index />
  </div>
</template>

<script>
import TimerIndex from "../components/Timer/TimerIndex";

export default {
  components: { TimerIndex },
};
</script>

<style lang="scss">
.app--timer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  overflow-y: auto;
}
</style>
