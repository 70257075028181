<template>
  <div class="excel__import">
    <h1>Impordi tooted Excelist</h1>
    <p>
      Laadi üles XLSX-fail ja selle esimene leht kirjutatakse rakenduse tooteandmebaasi.
    </p>
    <p>NB! Kui mõnel faili lehel on peal tulbafilter, ei õnnestu faili importida.</p>

    <div class="excel__import__file">
      <file-input
        id="import-file"
        acceptedMimesList="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        @fileSelected="onFileSelected"
        @fileReset="reset"
      />
    </div>

    <button
      class="button excel__import__submit"
      v-if="file && !isUploadInProgress"
      @click="upload"
    >
      Impordi
    </button>

    <div v-if="isUploadInProgress" class="excel__import__success">Palun oota...</div>

    <div v-if="errorMessage" class="excel__import__error">
      {{ errorMessage }}
    </div>
    <div v-else-if="isSuccess" class="excel__import__success">
      <p>{{ successMessage }}</p>
      <a class="excel__import__link" v-if="importLogFileId" @click="downloadImportLog">
        <span v-if="!isLoadingLogFile">Laadi alla impordilogi</span>
        <span v-else>Laadin, palun oota...</span>
      </a>
    </div>
  </div>
</template>

<script>
import config from "../../../config";
import FileInput from "../../Shared/FileInput";

export default {
  components: { FileInput },
  data: () => ({
    file: null,
    isUploadInProgress: false,
    isSuccess: false,
    errorMessage: "",
    successMessage: "",
    importLogFileId: null,
    importLogFileName: null,
    isLoadingLogFile: false,
  }),
  methods: {
    onFileSelected(file) {
      this.file = file;
      this.isSuccess = false;
      this.errorMessage = "";
    },
    reset() {
      this.isUploadInProgress = false;
      this.file = null;
    },
    upload() {
      this.isUploadInProgress = true;

      let formData = new FormData();
      formData.append("file", this.file);

      this.$http
        .post(config.$apiEndpoints.$excelProductsImport, formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((res) => {
          this.isSuccess = true;
          this.importLogFileId = res.data.logFile.fileId;
          this.importLogFileName = res.data.logFile.fileName;
          this.successMessage = `Import õnnestus! Imporditi ${res.data.numberOfProductsImported} toodet.`;
        })
        .catch((e) => {
          const res = e.response ? e.response.data : {};

          if (!res.messages) {
            res.messages = [];

            if (res.message) {
              res.messages.push(res.message);
            }
          }

          let errorMessages = res.messages;
          errorMessages.unshift("Import ei õnnestunud.");

          this.errorMessage = errorMessages.join(" ");
        })
        .finally(this.reset);
    },
    downloadImportLog() {
      if (!this.importLogFileId) {
        return;
      }

      this.isLoadingLogFile = true;

      let url = config.$apiEndpoints.$excelProductsImportFile.replace(
        ":id",
        this.importLogFileId
      );

      this.$http
        .get(url, {
          responseType: "arraybuffer",
        })
        .then((res) => {
          let fileUrl = window.URL.createObjectURL(new Blob([res.data]), {
            type: this.mimeType,
          });
          let fileLink = document.createElement("a");

          fileLink.href = fileUrl;
          fileLink.setAttribute("download", this.importLogFileName);

          document.body.appendChild(fileLink);

          fileLink.click();
        })
        .catch(() => alert("Faili ei õnnestunud alla laadida."))
        .finally(() => (this.isLoadingLogFile = false));
    },
  },
};
</script>

<style lang="scss">
.excel__import {
  &__error,
  &__success {
    padding: 15px 0;
    font-size: 16px;
    font-style: italic;
  }
  &__error {
    color: $color-accent;
  }

  &__success {
    color: green;
  }

  &__file {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  &__filename {
    margin: 0 15px;
    font-style: italic;
  }

  &__clear {
    font-size: 20px;
    cursor: pointer;
  }

  &__submit {
    margin-top: 15px;
  }

  &__link {
    text-decoration: underline;
    cursor: pointer;
  }
}
</style>
