<template>
  <div class="product-detail" v-if="show" ref="wrapper" tabindex="0">
    <product-detail-section
      v-for="section in sections"
      v-bind:key="section.name"
      v-bind:section="section"
      v-bind:product="product"
      v-bind:props="props"
      v-bind:isCollapsed="collapsedSections[section.name]"
      v-on:toggled="toggleSection(section.name)"
    >
    </product-detail-section>
  </div>
</template>

<script>
import ProductDetailSection from "./ProductDetailSection";
import SectionConfig from "./sections.json";

export default {
  components: {
    ProductDetailSection,
  },

  created() {
    // Set "Alt + K" to toggle all sections
    window.addEventListener("keydown", this.handleKeyDown);
    window.addEventListener("keyup", this.handleKeyUp);

    this.fetchProductDetail();
  },
  destroyed() {
    window.removeEventListener("keydown", this.handleKeyDown);
    window.removeEventListener("keyup", this.handleKeyUp);
  },

  data: () => ({
    show: false,
    props: {},
    product: {},
    sections: SectionConfig.sections,
    collapsedSections: {
      productData: false,
      packagingData: false,
      machineSettings: false,
      bulkPackagingData: false,
      palletData: false,
      additionalImages: false,
    },
    isAltPressed: false,
  }),

  methods: {
    handleKeyDown: function (e) {
      if (e.keyCode === 18) {
        // Alt key
        this.isAltPressed = true;
      } else if (this.isAltPressed) {
        e.preventDefault();

        switch (e.keyCode) {
          case 75: // K key
            this.toggleAllSections(true);
            break;
          case 76: // L key
            this.toggleAllSections(false);
            break;
        }
      }
    },
    handleKeyUp: function (e) {
      if (e.keyCode === 18) {
        // Alt key
        this.isAltPressed = false;
      }
    },

    focusOnWrapper() {
      if (this.$refs.wrapper) {
        this.$refs.wrapper.focus();
      }
    },

    fetchProductDetail() {
      const id = this.$route.params.id;

      if (!id) {
        return;
      }

      let url = this.api.$productDetail.replace(":id", id);

      this.$http.get(url).then((result) => {
        this.props = result.data.props;
        this.product = result.data.item;
        this.show = true;

        this.$nextTick(() => {
          this.focusOnWrapper();
        });
      });
    },

    toggleSection(sectionName) {
      this.collapsedSections[sectionName] = !this.collapsedSections[sectionName];
    },
    toggleAllSections(collapse) {
      for (let sectionName in this.collapsedSections) {
        this.collapsedSections[sectionName] = collapse;
      }
    },
  },
  watch: {
    // Fetch data again when route changes
    // (new product is requested)
    $route: "fetchProductDetail",
  },
};
</script>

<style lang="scss">
.product-detail {
  box-sizing: border-box;

  &:focus {
    outline: none;
  }
}

.tablet .product-detail {
  padding-top: 45px;
}
</style>
