import Vue from "vue"
import App from "./App.vue"

import store from "./store"
import config from "./config"
import { router } from "./router"
import http from "./http"
import { isUsingTablet } from "./mobile";

import VModal from "vue-js-modal"
import vSelect from "vue-select"
import SlideUpDown from "vue-slide-up-down"
import { Datepicker } from '@livelybone/vue-datepicker'
import EnlargeableImage from "@diracleo/vue-enlargeable-image"

import GuestLayout from "./layouts/GuestLayout"
import TimerLayout from "./layouts/TimerLayout"
import ProductsLayout from "./layouts/ProductsLayout"
import AdminLayout from "./layouts/AdminLayout"

Vue.config.productionTip = false

Vue.prototype.appConfig = config
Vue.prototype.api = config.$apiEndpoints
Vue.prototype.$store = store
Vue.prototype.$http = http()
Vue.prototype.$isTablet = isUsingTablet;

Vue.use(VModal)

vSelect.props.components.default = () => ({
  Deselect: {
    render: createElement => createElement('i', { class: 'fal fa-times' }),
  },
  OpenIndicator: {
    render: createElement => createElement('i', { class: 'fal fa-chevron-down' }),
  },
});

Vue.component('v-select', vSelect)
Vue.component('slide-up-down', SlideUpDown)
Vue.component('date-picker', Datepicker);
Vue.component("enlargeable-image", EnlargeableImage)

Vue.component("guest-layout", GuestLayout)
Vue.component("timer-layout", TimerLayout)
Vue.component("products-layout", ProductsLayout)
Vue.component("admin-layout", AdminLayout)

new Vue({
  router,
  render: h => h(App),
}).$mount("#app")
