<template>
  <ul class="product-list">
    <router-link
      :to="{ name: 'productDetail', params: { id: product.id } }"
      tag="li"
      class="product-list__item"
      v-for="product in products"
      :key="product.id"
    >
      <span class="product-list__ean">{{ product.ean }}</span>
      <span class="product-list__name">{{ product.productName }}</span>
    </router-link>
  </ul>
</template>

<script>
export default {
  props: ["products"]
}
</script>

<style lang="scss">
.product-list {
  &--empty {
    padding: 15px 30px;
    font-style: italic;
  }

  &--loading {
    margin-top: 30px;
    text-align: center;
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 15px $sidebar-padding;
    border-bottom: $border;
    cursor: pointer;

    &:last-child {
      border: none;
    }

    &:hover {
      background-color: $color-light;
      border-bottom: 1px solid $color-light;
    }
  }

  .router-link-active {
    background: $color-dark;
    border-bottom: 1px solid $color-dark;
    color: $background-color;
  }

  &__ean {
    font-weight: $font-weight-medium;
    font-size: $font-size-emphasis;
    letter-spacing: 0.5px;
  }

  &__name {
    font-style: italic;
    font-size: $font-size-text;
  }

  &__ean,
  &__name {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.tablet .product-list {
  &--empty,
  &__ean {
    font-size: 1.35em;
  }
  &__name {
    font-size: 1.2em;
  }
}
</style>
