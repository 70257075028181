<template>
  <div id="app" :class="{ tablet: $isTablet }">
    <top-bar />
    <component :is="layout"></component>
  </div>
</template>

<script>
const defaultLayout = "guest";

import TopBar from "./layouts/TopBar";

export default {
  components: { TopBar },
  name: "App",
  computed: {
    layout() {
      return (this.$route.meta.layout || defaultLayout) + "-layout";
    },
  },
  created() {
    let self = this;

    // Check for expired JWT
    this.$http.interceptors.response.use(undefined, function (err) {
      return new Promise(function () {
        if (err.response && err.response.status === 401) {
          self.$store.dispatch("logout").then(() => self.$router.push({ name: "login" }));
        }

        throw err;
      });
    });
  },
};
</script>

<style lang="scss">
@import "./assets/scss/index.scss";

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: $font-sans-serif;
  color: $text-color;
}

h1,
h2,
h3,
h4 {
  text-transform: uppercase;
}

ul,
li {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

select {
  padding: 9px 5px;
  font-size: $font-size-text;
  font-family: inherit;
  border: $border;

  &:active,
  &:focus {
    outline: none;
    border: 1px solid $color-dark;
  }
}

.app {
  position: relative;

  &__sidebar {
    position: fixed;
    width: $sidebar-width-max;
    height: 100%;
    padding: 0;
    border-right: $border;

    @media (max-width: $breakpoint-medium) {
      width: $sidebar-width-min;
    }

    .ps-container {
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }

  &__content {
    position: fixed;
    top: 0;
    bottom: 0;
    left: $sidebar-width-max;
    right: 0;
    padding: 0;
    overflow: auto;

    @media (max-width: $breakpoint-medium) {
      left: $sidebar-width-min;
    }
  }
}

.button {
  display: inline-block;
  background: $color-dark;
  color: $background-color;
  border: 1px solid $color-dark;
  font-family: $font-sans-serif;
  font-size: 16px;
  padding: 10px 15px;
  letter-spacing: 1px;
  font-weight: $font-weight-medium;
  text-transform: uppercase;
  border-radius: 3px;
  cursor: pointer;
  transition: all 150ms ease-in-out;

  &:focus,
  &:active {
    outline: none;
  }

  &:hover {
    background-color: $color-medium;
    border-color: $color-medium;
  }

  &--light {
    background-color: $background-color;
    border: 1px solid $color-dark;
    color: $color-dark;

    &:hover {
      background-color: $color-light;
      border-color: $color-light;
    }
  }

  &--accent {
    background-color: $color-accent;
    border-color: $color-accent;

    &:hover {
      background-color: darken($color-accent, 7%);
      border-color: darken($color-accent, 7%);
    }
  }

  &--medium {
    background-color: $color-light;
    border: 1px solid $color-dark;
    color: $color-dark;

    &:hover {
      background-color: $background-color;
    }
  }

  &--xs {
    padding: 5px 10px;
    font-size: 13px;
  }

  &:disabled {
    background-color: $border-color;
    border: $border;
    cursor: default;
  }

  &--add {
    i {
      position: relative;
      top: 1px;
      margin-right: 5px;
    }
  }
}

input,
textarea {
  font-family: inherit;
  border: $border;
  outline: none;
  box-shadow: none;

  &:focus,
  &:active {
    outline: none;
    border: 1px solid $color-dark;
  }
}

.tablet {
  .app {
    &__sidebar {
      width: 35%;
      overflow-y: auto;
    }

    &__content {
      left: 35%;
      padding-top: 50px;
    }
  }
}
</style>
