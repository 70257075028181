<template>
  <div class="file-link">
    <div v-if="row.type === 'import'">
      <a v-if="!isLoading" @click="fetchFile" title="Laadi alla">Impordi logifail</a>
      <span v-else>Laadin...</span>
    </div>
    <div v-else>{{ row.prevVal || "-" }}</div>
  </div>
</template>

<script>
export default {
  props: ["row"],
  data: () => ({
    isLoading: false,
    fileId: null,
    fileName: null,
    mimeType: null,
  }),
  methods: {
    fetchFile() {
      this.isLoading = true;
      let url = this.api.$excelProductsImportFile.replace(":id", this.fileId);

      this.$http
        .get(url, { responseType: "arraybuffer" })
        .then((res) => {
          let fileUrl = window.URL.createObjectURL(new Blob([res.data]), {
            type: this.mimeType,
          });

          let fileLink = document.createElement("a");

          fileLink.href = fileUrl;

          fileLink.setAttribute("download", this.fileName);
          document.body.appendChild(fileLink);

          fileLink.click();
        })
        .catch((e) => {
          alert("Esines viga: faili ei õnnestunud alla laadida.");
          console.log(e);
        })
        .finally(() => (this.isLoading = false));
    },
  },
  created() {
    if (this.row.type === "import") {
      this.fileId = this.row.logFile.fileId;
      this.fileName = this.row.logFile.fileName;
      this.mimeType = this.row.logFile.mimeType;
    }
  },
};
</script>

<style lang="scss">
.file-link {
  a {
    color: $color-dark;
    text-decoration: underline;
    cursor: pointer;
  }
}
</style>
