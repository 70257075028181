<script>

/**
 * Loads options for select inputs (orders, workers, workstations);
 * loads product when order is selected
 */
export default {
  data: () => ({
    isLoadingOptions: true,
    isLoadingProduct: false,

    productionOrders: [],
    workstations: [],
    workers: [],

    selectedProductionOrder: null,
    selectedWorker: null,
    selectedWorkstation: null,
    selectedProduct: null,

    failedToLoadOptions: false,
    errors: [],
  }),
  created() {
    this.fetchOptions();
  },
  methods: {
    fetchOptions() {
      this.$http
        .get(this.api.$timerOptions)
        .then((res) => this.populateSelects(res.data))
        .catch(this.handleFetchOptionsError)
        .finally(() => (this.isLoadingOptions = false));
    },
    populateSelects(data) {
      if (data.orders && data.orders.length) {
        this.populateProductionOrderSelect(data.orders);
      }

      if (data.workers && data.workers.length) {
        this.populateWorkersSelect(data.workers);
      }

      if (data.workstations && data.workstations.length) {
        this.populateWorkstationsSelect(data.workstations);
      }
    },
    populateProductionOrderSelect(items) {
      let opts = [];

      for (let i = 0, len = items.length; i < len; i++) {
        let item = items[i];
        let label = item.orderNumber;

        if (item.title && item.title.trim()) {
          label += " - " + item.title;
        }

        opts.push({
          id: item.id,
          label: label,
        });
      }

      this.productionOrders = opts;
    },
    populateWorkersSelect(items) {
      let opts = [];

      for (let i = 0, len = items.length; i < len; i++) {
        let item = items[i];

        opts.push({
          id: item.id,
          label: `${item.firstName} ${item.lastName}`,
        });
      }

      this.workers = opts;
    },
    populateWorkstationsSelect(items) {
      let opts = [];

      for (let i = 0, len = items.length; i < len; i++) {
        let item = items[i];

        opts.push({
          id: item.id,
          label: item.title,
        });
      }

      this.workstations = opts;
    },
    /**
     * Updates the displayed product
     * when an order is (de)selected.
     */
    onSelectedOrderChanged(order) {
      if (order && order.id) {
        this.fetchProductByOrderId(order.id);
      } else {
        this.selectedProduct = null;
      }
    },
    fetchProductByOrderId(orderId) {
      this.isLoadingProduct = true;
      this.selectedProduct = null;

      const url = this.api.$timerProductByOrderId.replace(":order-id", orderId);

      this.$http
        .get(url)
        .then((res) => {
          this.selectedProduct = res.data;
        })
        .catch((e) => {
          const res = e.response ? e.response.data : {};
          this.errors = [res.message || "Toote laadimine ebaõnnestus."];
        })
        .finally(() => (this.isLoadingProduct = false));
    },
    handleFetchOptionsError(e) {
      this.failedToLoadOptions = true;

      const res = e.response ? e.response.data : {};
      const status = e.response ? e.response.status : null;

      if (!status || status === 404) {
        this.errors = ["Ajaarvestuse vormi ei õnnestunud laadida."];
        return;
      }

      if (!res.messages) {
        res.messages = [];

        if (res.message) {
          res.messages.push(res.message);
        }
      }

      this.errors = res.messages;
    },
  },
};
</script>
