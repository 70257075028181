<template>
  <div class="product-list__search">
    <search-input
      :placeholder="$isTablet ? 'Otsi...' : 'Sisesta otsingusõna...'"
      :isFocused="isFocused"
      maxlength="50"
      @onTermChanged="onTermChanged"
      @blur="isFocused = false"
    />

    <slide-up-down
      class="product-list__search__props"
      :duration="50"
      :active="isExpanded"
    >
      <span
        class="product-list__search__prop"
        v-bind:class="{ active: searchProp === searchableProp.prop }"
        v-for="searchableProp in searchableProps"
        :key="searchableProp.prop"
        @click="onSearchPropChanged(searchableProp.prop, searchableProp.title)"
      >
        {{ searchableProp.title }}
      </span>
    </slide-up-down>

    <button
      class="button product-list__search__toggle"
      @click="toggle()"
      v-bind:title="isExpanded ? 'Sulge filtrid' : 'Ava filtrid'"
    >
      <span v-if="isExpanded"><i class="fal fa-chevron-up"></i></span>
      <span v-else><i class="fal fa-chevron-down"></i></span>
    </button>
  </div>
</template>

<script>
import _ from "lodash";
import SearchInput from "../../Shared/SearchInput";

const SEARCHABLE_PROPS = [
  { prop: "", title: "Kõik" },
  { prop: "productName", title: "Tootenimi" },
  { prop: "sampleNumber", title: "Näidise nr" },
  { prop: "ean", title: "EAN kood" },
  { prop: "brand", title: "Kaubamärk" },
  { prop: "subgroup", title: "Alagrupp" },
  { prop: "productGroup", title: "Üldine tootegrupp" },
];

export default {
  components: { SearchInput },
  props: ["products"],

  data: () => ({
    isExpanded: false,
    isFocused: true,
    isAltPressed: false,
    searchableProps: SEARCHABLE_PROPS,
    searchTerm: "",
    searchProp: "",
    searchPropTitle: "",
  }),

  created: function () {
    // Set "Alt + F" to toggle filters
    window.addEventListener("keydown", this.handleKeyDown);
    window.addEventListener("keyup", this.handleKeyUp);
  },
  destroyed: function () {
    window.removeEventListener("keydown", this.handleKeyDown);
    window.removeEventListener("keyup", this.handleKeyUp);
  },
  methods: {
    handleKeyDown: function (e) {
      if (e.keyCode === 18) {
        // Alt key
        this.isAltPressed = true;
      } else if (this.isAltPressed) {
        e.preventDefault();

        switch (e.keyCode) {
          case 70: // F key
            this.toggle();
            break;
          case 79: // O key
            this.focusInput();
            break;
        }
      }
    },
    handleKeyUp: function (e) {
      if (e.keyCode === 18) {
        // Alt key
        this.isAltPressed = false;
      }
    },

    focusInput: function () {
      this.isFocused = true;
    },

    toggle: function () {
      this.isExpanded = !this.isExpanded;
    },

    onTermChanged: _.debounce(function (term) {
      this.searchTerm = term;
      this.submit();
    }, 1000),

    onSearchPropChanged: function (propName, propTitle) {
      this.searchProp = propName;
      this.searchPropTitle = propTitle;

      if (this.searchTerm.trim()) {
        this.submit();
      }
    },

    submit: function () {
      let data = {
        term: this.searchTerm.trim(),
        prop: this.searchProp,
        propTitle: this.searchPropTitle,
      };

      this.$emit("filtered", data);
    },

    reset: function () {
      this.searchTerm = "";
      this.searchProp = "";
      this.searchPropTitle = "";
      this.onTermChanged();
    },
  },
};
</script>

<style lang="scss">
.product-list__search {
  position: relative;
  flex: none;
  padding: 15px $sidebar-padding;

  &__props {
    margin-top: 10px;
    padding: 5px 5px 5px 15px;
    background: lighten($border-color, 8%);
  }

  &__prop {
    position: relative;
    display: inline-block;
    margin-right: 15px;
    margin-bottom: 5px;
    font-size: 14px;
    cursor: pointer;

    &::after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 0;
      background: $color-dark;
    }

    &.active {
      color: $color-dark;
      font-weight: $font-weight-bold;
      cursor: default;

      &::after {
        height: 2px;
      }
    }

    &:hover {
      color: $color-dark;

      &::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 1px;
        background: $color-dark;
      }
    }
  }

  &__toggle {
    width: 100%;
    margin-top: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 14px;
    font-weight: $font-weight-light;
    text-transform: none;
    letter-spacing: 0;
    color: $color-dark;
    border: 1px solid lighten($border-color, 8%);
    background: $background-color;

    &:hover {
      background: lighten($border-color, 8%);
      border: 1px solid lighten($border-color, 8%);
    }

    i {
      margin-right: 5px;
    }
  }
}

.tablet .product-list__search {
  &__prop {
    font-size: 1.2em;
    padding-top: 10px;
    padding-bottom: 10px;
    display: block;
  }

  &__toggle {
    font-size: 1.4em;
    padding-top: 10px;
    padding-bottom: 10px;
  }
}
</style>
