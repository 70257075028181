<template>
  <div class="production-orders admin-page">
    <div class="admin-page__header">
      <h1>Tootmistellimused</h1>
    </div>

    <div class="production-orders__excel">
      <button class="button" @click="importOrders" title="Impordi Excelist">
        <i class="fal fa-file-import"></i> Import
      </button>
      <button
        class="button"
        @click="exportOrders"
        :disabled="isProcessingExport"
        title="Ekspordi Excelisse"
      >
        <i v-if="isProcessingExport" class="spinner fal fa-spinner"></i>
        <span v-else><i class="fal fa-file-export"></i> Eksport</span>
      </button>
    </div>

    <datatable :options="tableOptions" :reloadTrigger="reloadTrigger" />
  </div>
</template>

<script>
import FileSaver from "file-saver";
import Datatable from "../Shared/Datatable";
import ExcelProductionOrdersImport from "./ExcelProductionOrdersImport";

export default {
  components: { Datatable },
  data: () => ({
    isProcessingExport: false,
    reloadTrigger: 0,
    tableOptions: {
      sortedType: "desc",
      columns: [
        {
          title: "Tellimuse number",
          key: "orderNumber",
          sortedKey: "orderNumber",
          sortedType: "desc",
          sortable: true,
        },
        {
          title: "Tellimuse nimetus",
          key: "title",
        },
        {
          title: "Kogus",
          key: "quantity",
        },
        {
          title: "Toode",
          key: "productEan",
        },
      ],
      search: true,
      searchPlaceholder: "Otsi...",
      source: null,
    },
  }),
  created() {
    this.tableOptions.source = this.appConfig.$apiUrl + this.api.$productionOrderList;
  },
  methods: {
    importOrders() {
      this.$modal.show(
        ExcelProductionOrdersImport,
        {},
        { height: "auto" },
        {
          closed: this.onImportModalClosed,
        }
      );
    },
    onImportModalClosed() {
      this.reloadTrigger++;
    },
    exportOrders() {
      this.isProcessingExport = true;

      this.$http({
        url: this.api.$excelProductionOrdersExport,
        method: "GET",
        responseType: "blob",
      })
        .then((response) => {
          FileSaver.saveAs(
            new Blob([response.data]),
            "Tootmistellimused-" + Date.now() + ".xlsx"
          );
        })
        .catch(() => alert("Eksport ebaõnnestus."))
        .finally(() => {
          this.isProcessingExport = false;
        });
    },
  },
};
</script>

<style lang="scss">
.production-orders {
  margin: 0 30px 30px 0;

  .datatable table {
    width: 100%;
  }

  &__excel {
    text-align: right;

    .button {
      margin-right: 15px;

      &:last-child {
        margin-right: 0;
      }

      i {
        margin-right: 7px;
      }
    }
  }
}
</style>
