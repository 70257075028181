<template>
  <div class="login">
    <form @submit.prevent="login">
      <div class="login__field">
        <i class="icon fal fa-user"></i>
        <input
          type="text"
          id="username"
          placeholder="Kasutajanimi"
          v-model="loginModel.username"
          maxlength="50"
          ref="username"
        />
      </div>

      <div class="login__field">
        <i class="icon fal fa-key"></i>
        <input
          type="password"
          id="password"
          placeholder="Salasõna"
          v-model="loginModel.password"
        />
      </div>

      <slide-up-down class="login__error" :duration="200" :active="!!errorMsg">
        {{ errorMsg }}
      </slide-up-down>

      <button class="button" type="submit" :disabled="isLoading" v-if="!isLoading">
        Logi sisse
      </button>
      <i v-else class="spinner fal fa-spinner"></i>
    </form>
  </div>
</template>

<script>
export default {
  data: () => ({
    loginModel: {
      username: "",
      password: "",
    },
    isLoading: false,
    errorMsg: "",
  }),
  methods: {
    login() {
      this.errorMsg = "";
      this.isLoading = true;

      this.loginModel.username = this.loginModel.username.trim();

      this.$store
        .dispatch("login", this.loginModel)
        .then(() => {
          this.$router.push({ name: "home" });
        })
        .catch((e) => {
          const res = e.response ? e.response.data : {};
          this.errorMsg = res.message || "Vale kasutajanimi või salasõna.";
        })
        .finally(() => (this.isLoading = false));
    },
  },
  mounted() {
    this.$refs.username.focus();
  },
};
</script>

<style lang="scss">
.login {
  width: 300px;
  font-size: 16px;

  form {
    width: 100%;
  }

  &__field {
    position: relative;

    .icon {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 45px;
      background: $color-dark;
      color: $background-color;
    }

    input {
      display: block;
      width: 100%;
      margin-bottom: 15px;
      padding: 10px 15px 10px 55px;
      font-family: inherit;
      font-size: inherit;
    }
  }

  button,
  .spinner {
    float: right;
  }

  button {
    min-height: 41px;
    font-size: $font-size-text;
  }

  &__error {
    clear: both;
    margin-bottom: 15px;
    color: $color-accent;
    font-style: italic;
    font-size: $font-size-text;
    text-align: right;
  }
}

.tablet {
  .login {
    width: 60%;

    &__field {
      margin-bottom: 30px;

      .icon {
        width: 65px;
        font-size: 26px;
      }
    }

    input {
      font-size: 1.6em;
      padding-left: 80px;
    }

    .button {
      font-size: 1.6em;
      padding-left: 30px;
      padding-right: 30px;
    }
  }
}
</style>
