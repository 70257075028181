<template>
  <div class="timer-summary admin-page">
    <div class="admin-page__header">
      <h1>Tellimuse kokkuvõte</h1>
    </div>

    <div class="timer-summary__search">
      <i class="spinner fal fa-spinner" v-if="isLoadingOrders"></i>
      <form @submit.prevent="search" v-else>
        <i class="icon icon--search fal fa-search"></i>
        <v-select
          v-model="selectedProductionOrder"
          :options="productionOrders"
          placeholder="Palun vali tellimus..."
          @input="onOrderSelected"
        >
          <div slot="no-options">Tellimust ei leitud.</div>
        </v-select>
      </form>
    </div>

    <i class="spinner fal fa-spinner" v-if="isLoadingSummary"></i>

    <div
      class="no-results"
      v-if="!isLoadingSummary && selectedProductionOrder && !summaryRows.length"
    >
      Tellimuse "{{ selectedProductionOrder.label}}" kohta ei ole tööaega arvestatud.
    </div>

    <div class="error" v-if="!isLoadingSummary && summaryError">
      {{ summaryError }}
    </div>

    <div class="datatable" v-if="!isLoadingSummary && summaryRows.length">
      <table class="table">
        <thead>
          <th>Töökeskus</th>
          <th>Tööaeg minutites</th>
          <th>Tööaeg tundides</th>
          <th>Alguskuupäev</th>
          <th>Lõppkuupäev</th>
          <th>Töölised</th>
        </thead>
        <tbody>
          <tr v-for="row in summaryRows" :key="row.workstation">
            <td>{{ row.workstation }}</td>
            <td>{{ row.minutesWorked }}</td>
            <td>{{ row.hoursWorked }}</td>
            <td>{{ row.startDate }}</td>
            <td>{{ row.endDate }}</td>
            <td>{{ row.workers.join(', ') }}</td>
          </tr>

          <tr class="separator">
            <td class="invisible" colspan="6">&nbsp;</td>
          </tr>

          <tr>
            <td class="bold table-summary">Tööaeg kokku:</td>
            <td class="bold">{{ totalMinutesWorked }}</td>
            <td class="bold">{{ totalHoursWorked }}</td>
            <td class="invisible" colspan="3"></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
const moment = require("moment-timezone");

export default {
  data: () => ({
    selectedProductionOrder: null,

    productionOrders: [],
    summaryRows: [],
    totalMinutesWorked: 0,
    totalHoursWorked: 0,

    isLoadingOrders: true,
    isLoadingSummary: false,

    summaryError: "",
  }),
  created() {
    this.getOrders();
  },
  methods: {
    getOrders() {
      this.$http
        .get(this.api.$timerOptions)
        .then((res) => {
          let opts = [];
          let items = res.data.orders;

          for (let i = 0, len = items.length; i < len; i++) {
            let item = items[i];
            let label = item.orderNumber;

            if (item.title && item.title.trim()) {
              label += " - " + item.title;
            }

            opts.push({
              id: item.orderNumber,
              label: label,
            });
          }

          this.productionOrders = opts;
        })
        .catch((e) => {
          alert("Tellimuste nimekirja ei õnnestunud laadida.");
          console.log(e.response);
        })
        .finally(() => (this.isLoadingOrders = false));
    },
    onOrderSelected(order) {
      if (order) {
        this.getSummaryRows(order.id);
      } else {
        this.summaryRows = [];
      }
    },
    getSummaryRows(orderNumber = null) {
      this.isLoadingSummary = true;

      this.summaryError = "";
      this.summaryRows = [];
      this.totalMinutesWorked = 0;
      this.totalHoursWorked = 0;

      this.$http
        .post(this.api.$timerSummary, { orderNumber: orderNumber })
        .then((res) => {
          this.totalMinutesWorked = res.data.totalMinutesWorked;
          this.totalHoursWorked = Math.round((res.data.totalHoursWorked + Number.EPSILON) * 10) / 10;

          let rows = [];

          for (let i = 0, len = res.data.rows.length; i < len; i++) {
            let row = res.data.rows[i];

            let startDate = moment
              .utc(row.startDt)
              .tz(this.appConfig.timezone)
              .format("DD.MM.YYYY");

            let endDate = row.endDt
              ? moment.utc(row.endDt).tz(this.appConfig.timezone).format("DD.MM.YYYY")
              : null;

            rows.push({
              workstation: row.workstation,
              minutesWorked: row.minutesWorked,
              hoursWorked: Math.round((row.hoursWorked + Number.EPSILON) * 10) / 10,
              workers: row.workers,
              startDate: startDate,
              endDate: endDate,
            });
          }

          this.summaryRows = rows;
        })
        .catch((e) => {
          this.summaryError = "Tellimuse kokkuvõtet ei õnnestunud laadida.";
          console.log(e.response);
        })
        .finally(() => (this.isLoadingSummary = false));
    },
  },
};
</script>

<style lang="scss">
.timer-summary {
  &__search {
    position: relative;
    max-width: 550px;
    margin-top: 30px;
    margin-bottom: 40px;

    .icon--search {
      position: absolute;
      padding: 9px 11px;
      font-size: 14px;

      top: 0;
      left: 0;
      background: $color-dark;
      color: $background-color;
      border: 1px solid $color-dark;
    }

    .v-select {
      margin-left: 38px;

      .vs__search {
        width: 0 !important;
        padding: 0 !important;
      }

      .vs__selected-options {
        padding-left: 10px;
      }
    }
  }

  .table {
    th,
    td.table-summary {
      background: $color-light;
      border-color: $color-medium;
    }

    td.invisible {
      border: none;
      background: transparent;
    }

    .separator td {
      padding: 15px 0;
    }

    td:nth-child(2),
    td:nth-child(3) {
      text-align: right;
    }
  }
}
</style>
