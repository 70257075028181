<template>
  <div class="top-bar">
    <button
      class="button button--label button--test"
      v-if="!appConfig.$isProduction"
      title="Oled testkeskkonnas!"
    >
      <i class="fad fa-exclamation-triangle"></i>Test
    </button>

    <button
      v-if="isTimerRoute"
      class="button button--products button--label"
      @click="$router.push({ name: 'productList' })"
    >
      <i class="fal fa-list-alt"></i><span v-if="1 || !$isTablet">Tootekataloog</span>
    </button>

    <button
      v-else-if="isLoggedIn"
      class="button button--label button--timer"
      @click="$router.push({ name: 'home' })"
    >
      <i class="fal fa-clock"></i><span v-if="1 || !$isTablet">Ajaarvestus</span>
    </button>

    <div v-show="isExpanded">
      <button class="button--user button button--label" v-if="isLoggedIn">
        <i class="fal fa-user"></i> {{ user.name }}
      </button>

      <button
        class="button button--tag button--admin-switch"
        v-if="isAdminLinkVisible"
        title="Admin"
        @click="$router.push({ name: 'admin' })"
      >
        <i class="fal fa-cog"></i>
      </button>

      <button
        class="button button--tag button--admin-switch"
        v-if="isProductListLinkVisible"
        title="Tooted"
        @click="$router.push({ name: 'productList' })"
      >
        <i class="fal fa-list-alt"></i>
      </button>

      <logout v-if="isLoggedIn" />
    </div>

    <button
      v-if="$route.meta.layout !== 'guest'"
      @click="isExpanded = !isExpanded"
      class="button toggle button--tag button--light"
      v-bind:class="{ expanded: isExpanded }"
      :title="isExpanded ? 'Sulge menüü' : 'Ava menüü'"
    >
      <i class="fal fa-ellipsis-h-alt"></i>
    </button>
  </div>
</template>

<script>
import Logout from "../components/Auth/Logout";

export default {
  components: { Logout },
  data: () => ({
    isExpanded: false,
  }),
  methods: {
    getUser() {
      return this.$store.state.user || {};
    },
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    isLoggedIn() {
      return !!this.$store.state.user;
    },
    isTimerRoute() {
      return this.$route.meta.layout === "timer";
    },
    isAdminLinkVisible() {
      const user = this.getUser();
      const isAdminRoute = this.$route.meta.layout === "admin";
      return !isAdminRoute && user.role === "admin";
    },
    isProductListLinkVisible() {
      const user = this.getUser();
      const isProductsRoute = this.$route.meta.layout === "products";
      return !isProductsRoute && user.role === "admin";
    },
  },
};
</script>

<style lang="scss">
.top-bar {
  position: absolute;
  top: -1px;
  right: 15px;
  display: flex;
  align-items: flex-start;
  background: $background-color;
  z-index: 5;

  .button {
    margin: 0;
    margin-right: 5px;
    padding: 0;
    outline: none;
    border: none;
    border-radius: 0;

    &--label {
      margin-right: 10px;
      padding: 13px 15px;
      font-size: 12px;
      text-transform: uppercase;
      letter-spacing: 1px;
      font-weight: $font-weight-bold;

      i {
        position: relative;
        top: 1px;
        margin-right: 8px;
        font-size: 16px;
      }
    }

    &--tag {
      padding: 10px 15px;

      a {
        color: $text-color;
      }
    }

    &--test,
    &--user {
      cursor: default;
    }

    &--test {
      color: $background-color;
      background: $color-accent;
      cursor: help;

      &:hover {
        background: $color-accent;
      }
    }

    &--user {
      background: $background-color;
      border: none;
      color: $text-color;
      text-transform: none;
      font-weight: $font-weight-medium;

      &:hover {
        background: transparent;
        border: none;
      }
    }

    &--timer,
    &--products {
      a {
        line-height: 1em;
        color: $background-color;
      }
    }

    &--admin-switch a {
      color: $background-color;
    }
  }

  .toggle {
    border: $border;

    i {
      position: relative;
      top: 0;
      left: 0;
      transform: rotate(0);
      transition: all 150ms ease-in-out;
    }

    &.expanded {
      i {
        top: 2px;
        left: -1px;
        transform: rotate(90deg);
      }
    }
  }
}

.tablet .top-bar {
  .button {
    &--label {
      padding: 20px 25px;
      font-size: 1.1em;

      i {
        position: relative;
        top: 1px;
        font-size: 1.2em;
        margin-right: 15px;
      }
    }

    &--tag {
      padding: 20px 25px 21px 25px;

      i {
        font-size: 1.2em;
      }
    }
  }
}
</style>
