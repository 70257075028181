<template>
  <div class="timer__clock">
    {{ timerComponents.join(":") }}
  </div>
</template>

<script>
const moment = require("moment-timezone");

export default {
  props: ["startDt", "endDt"],
  data: () => ({
    timerComponents: ["--", "--", "--"],
    timerInterval: null,
  }),
  created() {
    if (this.endDt) {
      // Display time ended
      this.calculateElapsedTime(this.endDt);
    } else {
      // Start counting time
      this.timerInterval = setInterval(this.calculateElapsedTime, 1000);
    }
  },
  methods: {
    calculateElapsedTime(endDt = null) {
      let momentEndDt = endDt || moment();
      let diff = momentEndDt.diff(this.startDt);

      if (diff <= 0) {
        return;
      }

      let duration = moment.duration(diff);
      let elapsedDt = moment.utc(duration.asMilliseconds());

      let yearsElapsed = Math.floor(duration.asYears());
      let daysElapsed = Math.floor(duration.asDays());

      let hoursElapsed = elapsedDt.format("HH");
      let minutesElapsed = elapsedDt.format("mm");
      let secondsElapsed = elapsedDt.format("ss");

      let timerArr = [hoursElapsed, minutesElapsed, secondsElapsed];

      if (daysElapsed > 0) {
        timerArr.unshift(daysElapsed);
      }
      if (yearsElapsed > 0) {
        timerArr.unshift(yearsElapsed);
      }

      this.timerComponents = timerArr;
    },
    onEndDtChanged(newVal) {
      if (newVal) {
        // Timer was stopped;
        // stop counting and display end time
        clearInterval(this.timerInterval);
        this.calculateElapsedTime(newVal);
      }
    }
  },
  watch: {
    endDt: "onEndDtChanged"
  }
};
</script>

<style lang="scss">
.timer__clock {
  font-size: 26px;
  font-weight: $font-weight-medium;
  color: $color-dark;

  .vs__search {
    width: 0 !important;
  }
}

.tablet .timer__clock {
  font-size: 2.4em;
}
</style>
