<template>
  <div class="product-detail__image">
    <i v-if="isLoading" class="spinner fal fa-spinner"></i>

    <enlargeable-image
      v-else-if="imgSrc"
      :src="imgSrc"
      :src_large="imgSrc"
      animation_duration="300"
    />

    <span v-else>
      <span v-if="!errorMessage">x</span>
      <span class="error" v-else>{{ errorMessage }}</span>
    </span>
  </div>
</template>

<script>
import axios from "axios";

export default {
  props: ["fileId"],
  data: () => ({
    isLoading: false,
    imgSrc: null,
    errorMessage: null,
    reqCancelTokenSource: null,
  }),
  created() {
    this.fetchImage();
  },
  methods: {
    onFileIdChanged() {
      // Cancel request for previous file
      if (this.reqCancelTokenSource) {
        this.reqCancelTokenSource.cancel();
        this.reqCancelTokenSource = null;
      }

      // Fetch new image
      this.fetchImage();
    },
    fetchImage() {
      this.errorMessage = null;

      if (!this.fileId) {
        this.imgSrc = null;
        return;
      }

      this.isLoading = true;

      this.reqCancelTokenSource = axios.CancelToken.source();
      const cancelToken = this.reqCancelTokenSource.token;

      let url = this.api.$productFileGet.replace(":id", this.fileId);

      this.$http
        .get(url, {
          cancelToken: cancelToken,
        })
        .then((res) => {
          this.imgSrc = `data:${res.data.mimeType};base64,${res.data.content}`;
        })
        .catch((e) => {
          if (e.response) {
            this.errorMessage = e.response.data.message || "Pildi laadimine ebaõnnestus.";
            console.log("error", e.response);
          }
        })
        .finally(() => {
          this.isLoading = false;
          this.reqCancelTokenSource = null;
        });
    },
  },
  watch: {
    fileId: "onFileIdChanged",
  },
};
</script>

<style lang="scss">
.product-detail__image {
  padding-top: 10px;

  div img {
    cursor: pointer;
  }

  .enlargeable-image {
    .slot img {
      max-height: 200px;
    }

    .full {
      &.enlarging,
      &.enlarged,
      &.delarging,
      &.delarged {
        background-image: none !important;
      }

      &.enlarged {
        background-color: $background-color !important;
        overflow-y: auto;
      }

      & > img {
        position: absolute;
        top: 0;
        left: 0;
        object-fit: initial !important;
        width: initial !important;
        height: initial !important;
      }
    }
  }

  .error {
    color: $color-accent;
    font-size: 14px;
    font-style: italic;
  }
}
</style>
