<template>
    <div class="file-upload">
      <input :id="id" class="file-upload__input" type="file" ref="file" @change="onFileSelected" :accept="acceptedMimesList || '*'" />
      <label :for="id" class="button button--medium">Vali fail...</label>

      <div v-if="file" class="file-upload__filename">{{ file.name }}</div>

      <i class="fal fa-times-circle excel__import__clear" v-if="file" @click="reset"></i>
    </div>
</template>

<script>
export default {
    props: ["id", "acceptedMimesList"],
    data: () => ({
        file: null
    }),
    methods: {
        onFileSelected() {
            this.file = this.$refs.file.files.item(0);
            this.$emit("fileSelected", this.file);
        },
        reset() {
            this.file = null;
            this.$refs.file.value = null;
            this.$emit("fileReset");
        },
    },
};
</script>

<style lang="scss">
.file-upload {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    &__filename {
        margin: 0 15px;
        font-style: italic;
        max-width: 60%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    &__clear {
        font-size: 20px;
        cursor: pointer;
    }

    &__input {
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;

        & + label {
            display: inline-block;
            cursor: pointer;
            font-size: 14px;
        }

            & + label * {
            pointer-events: none;
        }
    }
}
</style>
