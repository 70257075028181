<template>
  <button
    class="button button--tag button--accent button--logout"
    @click="logout"
    title="Logi välja"
  >
    <i class="fal fa-sign-out"></i>
  </button>
</template>

<script>
export default {
  data: () => ({
    userName: "",
  }),
  methods: {
    logout() {
      this.$store.dispatch("logout").then(() => {
        this.$router.push({ name: "login" });
      });
    },
  },
  created() {
    this.userName = (this.$store.getters.user || {}).name;
  },
};
</script>

<style lang="scss">
button.button--logout.button--tag {
  border: 1px solid $color-accent;

  &:hover {
    border-color: darken($color-accent, 7%);
  }
}

.tablet {
  button.button--logout.button--tag {
    border: none;
  }
}
</style>
