<template>
  <div class="app app--guest">
    <login />
  </div>
</template>

<script>
import Login from "../components/Auth/Login";

export default {
  components: { Login },
};
</script>

<style type="scss">
  .app--guest {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;

  }
</style>
