<template>
  <div class="edit-log">
    <h1>Muudatuste logi</h1>

    <datatable :options="tableOptions" />
  </div>
</template>

<script>
import Datatable from "../Shared/Datatable";
import EditLogPropCol from "./EditLogPropCol";
import EditLogPrevValCol from "./EditLogPrevValCol";

export default {
  components: { Datatable },
  data: () => ({
    tableOptions: {
      sortedType: "desc",
      columns: [
        {
          title: "Aeg",
          key: "createdAt",
          sortedKey: "createdAt",
          sortedType: "desc",
          sortable: true,
          dateFormat: true,
          expectFormat: "DD.MM.YYYY HH:mm",
        },
        {
          title: "EAN / toiming",
          key: "ean",
        },
        {
          title: "Muudetud väärtus",
          type: "component",
          name: EditLogPropCol,
        },
        {
          title: "Eelnev väärtus",
          type: "component",
          name: EditLogPrevValCol,
        },
        {
          title: "Uus väärtus",
          key: "newVal",
        },
      ],
      source: null,
      search: true,
      searchPlaceholder: "Otsi EANi järgi...",
    },
  }),
  methods: {},
  created() {
    this.tableOptions.source = this.appConfig.$apiUrl + this.api.$editLogList;
  },
};
</script>

<style lang="scss">
.edit-log {
  margin: 0 $sidebar-padding $sidebar-padding 0;
}
</style>
