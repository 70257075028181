<template>
  <div class="timer">
    <div class="timer__header">
      <h1>Tööaja arvestus</h1>
    </div>

    <div class="timer__body">
      <i v-if="isLoadingOptions" class="spinner fal fa-spinner"></i>

      <div v-if="errors.length" class="timer__form__row errors">
        <i class="fal fa-exclamation-triangle"></i> {{ errors.join(" ") }}
      </div>

      <div v-if="unfinishedTimerError" class="timer__form__row errors">
        <i class="fal fa-exclamation-triangle"></i> {{ unfinishedTimerError }}
        <a @click="loadUnfinishedTimer"
          >Vajuta siia, kui soovid olemasolevat ajalogi jätkata.</a
        >
      </div>

      <form
        v-if="!isLoadingOptions && !failedToLoadOptions"
        class="timer__form"
        @submit.prevent
      >
        <div class="timer__form__row">
          <div class="product-detail__prop">
            <div class="product-detail__label">Tellimus</div>

            <div class="product-detail__val">
              <v-select
                v-if="!startDt"
                v-model="selectedProductionOrder"
                :options="productionOrders"
                @input="onSelectedOrderChanged"
                placeholder="Palun vali..."
              >
                <div slot="no-options">Tellimust ei leitud.</div>
              </v-select>

              <span v-else-if="selectedProductionOrder">{{
                selectedProductionOrder.label
              }}</span>
            </div>
          </div>

          <div class="product-detail__prop">
            <div class="product-detail__label">Toode</div>

            <div class="product-detail__val">
              <i v-if="isLoadingProduct" class="spinner fal fa-spinner"></i>
              <router-link
                class="product-link"
                v-else-if="selectedProduct"
                :to="{ name: 'productDetail', params: { id: selectedProduct.id } }"
                title="Mine tootekaardile"
                >{{ selectedProduct.ean }} -
                {{ selectedProduct.productName }}
              </router-link>
              <span v-else>&ndash;</span>
            </div>
          </div>
        </div>

        <div class="timer__form__row">
          <div class="product-detail__prop">
            <div class="product-detail__label">Töötaja</div>

            <div class="product-detail__val">
              <v-select
                v-if="!startDt"
                v-model="selectedWorker"
                :options="workers"
                placeholder="Palun vali..."
                @option:selected="onWorkerSelected"
              >
                <div slot="no-options">Töölist ei leitud.</div>
              </v-select>

              <span v-else-if="selectedWorker">{{ selectedWorker.label }}</span>
            </div>
          </div>

          <div class="product-detail__prop">
            <div class="product-detail__label">Töökeskus</div>

            <div class="product-detail__val">
              <v-select
                v-if="!startDt"
                v-model="selectedWorkstation"
                :options="workstations"
                placeholder="Palun vali..."
              >
                <div slot="no-options">Töökeskust ei leitud.</div>
              </v-select>

              <span v-else-if="selectedWorkstation">{{ selectedWorkstation.label }}</span>
            </div>
          </div>
        </div>
      </form>
    </div>

    <div class="timer__footer">
      <button
        class="button start"
        :disabled="!canStartTimer"
        v-if="!timerId"
        @click="startTimer"
      >
        <i class="fal fa-play-circle"></i>
        Alusta tööaega
      </button>

      <timer-clock v-if="startDt" :startDt="startDt" :endDt="endDt" />

      <button class="button button--accent stop" v-if="canStopTimer" @click="stopTimer">
        <i class="fal fa-stop-circle"></i>
        Peata tööaeg
      </button>

      <button class="button reload" v-if="endDt" @click="reloadPage">
        <i class="fal fa-redo-alt"></i>
        Alusta uut ajaarvestust
      </button>
    </div>
  </div>
</template>

<script>
const moment = require("moment-timezone");
import TimerClock from "./TimerClock";
import TimerMixin from "./TimerMixin";

export default {
  mixins: [TimerMixin],
  components: { TimerClock },
  data: () => ({
    isTablet: false,

    isProcessingStartOrStop: false,

    timerId: null,
    startDt: null,
    endDt: null,

    unfinishedTimerError: "",
    unfinishedTimer: {},

    localStorageTimerKey: "currentTimerId",
    localStorageWorkerIdKey: "selectedWorkerId",
    localStorageWorkerNameKey: "selectedWorkerName",
  }),
  created() {
    const userAgent = navigator.userAgent.toLowerCase();
    this.isTablet = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
      userAgent
    );

    // If an unfinished timer has been stored,
    // load that one automatically
    this.checkForRunningTimer();
  },
  methods: {
    checkForRunningTimer() {
      const runningTimerId = localStorage.getItem(this.localStorageTimerKey);

      if (runningTimerId) {
        this.getTimer(runningTimerId);
      }
    },
    getTimer(id) {
      const url = this.api.$timerGet.replace(":id", id);

      this.$http.get(url).then((res) => {
        const timer = res.data;

        // Do nothing if timer has already been stopped
        if (timer.endDt) {
          return;
        }

        this.selectedProductionOrder = {
          id: timer.productionOrder.productionOrderId.id,
          label: `${timer.productionOrder.productionOrderNumber} - ${
            timer.orderTitle || ""
          }`,
        };

        this.selectedWorker = {
          id: timer.worker.workerId,
          label: timer.worker.fullName,
        };

        this.selectedWorkstation = {
          id: timer.workstation.workstationId,
          label: timer.workstation.title,
        };

        this.selectedProduct = timer.product;

        this.timerId = timer.id;
        this.startDt = timer.startDt;

        localStorage.setItem(this.localStorageTimerKey, timer.id);
      });
    },
    startTimer() {
      this.isProcessingStartOrStop = true;
      this.errors = [];

      const data = {
        productionOrderId: this.selectedProductionOrder.id,
        workerId: this.selectedWorker.id,
        workstationId: this.selectedWorkstation.id,
      };

      this.$http
        .post(this.api.$timerStart, data)
        .then((res) => {
          this.timerId = res.data.id;
          this.startDt = moment.utc(res.data.startDt).tz(this.appConfig.timezone);

          // Store current timer in local storage
          // to reload in case page is refreshed while timer is running
          localStorage.setItem(this.localStorageTimerKey, res.data.id);
        })
        .catch((e) => {
          if (e.response && e.response.status === 409) {
            const data = e.response.data;

            this.unfinishedTimerError = data.message;
            this.unfinishedTimer = data.timer;
          }
        })
        .finally(() => (this.isProcessingStartOrStop = false));
    },
    stopTimer() {
      this.errors = [];

      if (!this.timerId) {
        return;
      }

      const url = this.api.$timerStop.replace(":id", this.timerId);

      this.$http
        .post(url)
        .then((res) => {
          this.endDt = moment.utc(res.data.endDt).tz(this.appConfig.timezone);

          // Remove timer from local storage
          localStorage.removeItem(this.localStorageTimerKey);
        })
        .catch(this.handleStopTimerError)
        .finally(() => (this.isProcessingStartOrStop = false));
    },
    handleStopTimerError(e) {
      const res = e.response ? e.response.data : {};

      if (!res.messages) {
        res.messages = [];

        if (res.message) {
          res.messages.push(res.message);
        }
      }

      this.errors = res.messages;
    },
    loadUnfinishedTimer() {
      if (!this.unfinishedTimer) {
        return;
      }

      this.unfinishedTimerError = "";

      this.timerId = this.unfinishedTimer.id;
      this.startDt = this.unfinishedTimer.startDt;

      localStorage.setItem(this.localStorageTimerKey, this.unfinishedTimer.id);
    },
    reloadPage() {
      this.$router.go();
    },

    onWorkerSelected(workerOption) {
      // Remember selected worker to select automatically for new timers
      localStorage.setItem(this.localStorageWorkerIdKey, workerOption.id);
      localStorage.setItem(this.localStorageWorkerNameKey, workerOption.label);
    },
    onWorkerOptionsLoaded(newOptions) {
      if (!newOptions.length) {
        return;
      }

      // Automatically select previously selected worker if set
      const prevWorkerId = localStorage.getItem(this.localStorageWorkerIdKey);
      const prevWorkerName = localStorage.getItem(this.localStorageWorkerNameKey);

      if (prevWorkerId && prevWorkerName) {
        this.selectedWorker = {
          id: prevWorkerId,
          label: prevWorkerName,
        };
      }
    },
  },
  computed: {
    canStartTimer() {
      // Must not already be started
      if (this.isProcessingStartOrStop || this.timerId || this.endDt) {
        return false;
      }

      // All dropdowns must have a value
      return (
        this.selectedProductionOrder && this.selectedWorker && this.selectedWorkstation
      );
    },
    canStopTimer() {
      // Must be started, cannot be finished
      return this.timerId && !this.isProcessingStartOrStop && !this.endDt;
    },
  },
  watch: {
    workers: "onWorkerOptionsLoaded",
  },
};
</script>

<style lang="scss">
.timer {
  width: 600px;
  margin: 0 auto;
  border-radius: 5px;
  border: $border;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

  &__header {
    padding: 18px 30px 15px 30px;
    background-color: $color-dark;
    color: $background-color;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;

    h1 {
      margin: 0;
      padding: 0;
      font-weight: $font-weight-medium;
      font-size: 20px;
    }
  }

  &__body,
  &__footer {
    padding: 15px 30px;
  }

  &__footer {
    display: flex;
    align-items: center;
    border-top: $border;
  }

  &__form {
    &__row {
      display: flex;
      flex-direction: column;
    }

    .vs__search {
      width: 0 !important;
    }
  }

  .errors {
    position: relative;
    padding: 10px 20px 10px 55px;
    font-size: 18px;
    background: $color-accent;
    color: $background-color;
    font-weight: $font-weight-medium;
    border-radius: 3px;

    i {
      position: absolute;
      top: 12px;
      left: 20px;
    }

    a {
      color: $background-color;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .product-detail__prop {
    padding-right: 0;
  }

  .start,
  .stop,
  .reload {
    display: flex;
    align-items: center;
    margin-left: auto;

    i {
      margin-right: 10px;
      font-size: 21px;
    }
  }

  .start:enabled {
    background-color: $color-success;
    border-color: $color-success;

    &:hover {
      background-color: darken($color-success, 7%);
      border-color: darken($color-success, 7%);
    }
  }

  .product-link {
    color: $color-medium;
    font-weight: $font-weight-medium;
    text-decoration: none;
  }
}

.tablet .timer {
  width: 75%;

  &__header {
    h1 {
      font-size: 2em;
    }
  }

  &__body {
    padding: 30px 45px;
  }

  .product-detail {
    &__prop {
      margin-bottom: 20px;
    }

    &__label {
      font-size: 1.3em;
    }

    &__val {
      font-size: 1.2em;

      span {
        font-size: 1.2em;
      }
    }
  }

  .vs {
    &__search {
      padding-top: 5px !important;
      padding-bottom: 5px !important;
      font-size: 1.2em;
    }

    &__dropdown-menu {
      padding: 0;
    }

    &__dropdown-option {
      padding-top: 10px;
      padding-bottom: 10px;
      border-bottom: $border;
      background-color: lighten($border-color, 10%);
      font-size: 1.2em;

      &:last-child {
        border-bottom: none;
      }

      &--highlight {
        background-color: $color-medium;
      }
    }

    &__clear {
      font-size: 1.1em;
      padding-left: 15px;
      padding-right: 15px;
    }

    &__open-indicator {
      transform: scale(1.2);
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  &__footer {
    padding: 15px 30px;

    .button {
      font-size: 1.4em;
      padding: 19px 35px;

      i {
        font-size: 1.2em;
      }
    }
  }
}
</style>
