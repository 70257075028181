<template>
  <div class="excel__export">
    <h1>Ekspordi tooted Excelisse</h1>
    <p>
      Allolevat nuppu vajutades laadid alla XLSX-faili, kuhu on kantud kõik rakenduse
      andmebaasis olevad tooted.
    </p>
    <button class="button" @click="excelExport" :disabled="isLoading">
      Ekspordi tooted
    </button>
    <div v-if="errorMessage" class="error">{{ errorMessage }}</div>
  </div>
</template>

<script>
import FileSaver from "file-saver";

export default {
  data: () => ({
    isLoading: false,
    errorMessage: "",
  }),
  methods: {
    excelExport() {
      this.errorMessage = "";
      this.isLoading = true;

      this.$http({
        url: this.api.$excelProductsExport,
        method: "GET",
        responseType: "blob",
      })
        .then((response) => {
          FileSaver.saveAs(
            new Blob([response.data]),
            "Tooteandmed-" + Date.now() + ".xlsx"
          );
        })
        .catch(() => {
          this.errorMessage = "Eksport ebaõnnestus.";
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style lang="scss">
.excel__export {
  p {
    padding: 15px 0;
  }

  .error {
      margin-top: 30px;
      color: $color-error;
      font-style: italic;
  }
}
</style>
