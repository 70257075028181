<template>
  <div class="workstations admin-page">
    <div class="admin-page__header">
      <h1>Töökeskused</h1>
      <button class="button button--add" title="Lisa töökeskus" @click="editItem(null)">
        <i class="fal fa-plus-circle"></i> Lisa uus
      </button>
    </div>

    <div class="datatable">
      <i v-if="isLoading" class="spinner fal fa-spinner"></i>

      <table v-else class="table">
        <thead>
          <th>Nimetus</th>
          <th>Halda</th>
        </thead>
        <tbody>
          <tr v-for="workstation in workstations" :key="workstation.id">
            <td>{{ workstation.title }}</td>
            <td class="actions">
              <button
                class="button button--xs button--light"
                title="Muuda"
                @click="editItem(workstation.id)"
              >
                <i class="fal fa-pencil-alt"></i>
              </button>
              <button
                class="button button--xs button--accent"
                title="Kustuta"
                @click="deleteItem(workstation.id)"
              >
                <i class="fal fa-trash-alt"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import EditWorkstation from "./EditWorkstation";

export default {
  data: () => ({
    isLoading: true,
    workstations: [],
  }),

  created() {
    this.fetchWorkstations();
  },

  methods: {
    fetchWorkstations() {
      this.isLoading = true;

      this.$http
        .get(this.api.$workstationList)
        .then((result) => {
          this.workstations = result.data;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    editItem(id) {
      this.$modal.show(
        EditWorkstation,
        {
          id: id,
          onChanged: this.fetchWorkstations,
        },
        { height: "auto" },
        {
          "before-close": this.onEditModalClosed,
        }
      );
    },
    onEditModalClosed(closeEvent) {
      // Reload list if changes were made
      if (closeEvent.params && closeEvent.params.isChanged === true) {
        this.fetchWorkstations();
      }
    },
    deleteItem(id) {
      if (!confirm("Kas oled kindel, et tahad töökeskuse kustutada?")) {
        return;
      }

      const url = this.api.$workstationRemove.replace(":id", id);

      this.$http
        .post(url)
        .then(this.fetchWorkstations)
        .catch((e) => {
          alert("Kustutamine ebaõnnestus.");
          console.log(e);
        });
    },
  },
};
</script>
