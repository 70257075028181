<template>
  <div class="timer-edit modal">
    <header>
      <h1>Muuda ajaarvestust</h1>

      <i
        class="icon icon--close fal fa-times-circle"
        @click="$emit('close')"
        title="Sulge"
      ></i>
    </header>

    <section>
      <i v-if="isLoadingData" class="spinner fal fa-spinner"></i>

      <form v-else autocomplete="off" v-on:submit.prevent="save">
        <div class="section">
          <label class="label" for="production-order">
            Tellimus <span class="required">*</span>
          </label>

          <v-select
            v-model="selectedProductionOrder"
            :options="productionOrders"
            @input="onProductionOrderChanged"
            placeholder="Palun vali..."
          >
            <div slot="no-options">Tellimust ei leitud.</div>
          </v-select>

          <div class="error error--inline" v-show="errorsByInput.productionOrder">
            {{ errorsByInput.productionOrder }}
          </div>
        </div>

        <div class="section">
          <label class="label" for="production-order">
            Tööline <span class="required">*</span>
          </label>

          <v-select
            v-model="selectedWorker"
            :options="workers"
            placeholder="Palun vali..."
            @input="validate"
          >
            <div slot="no-options">Töölist ei leitud.</div>
          </v-select>

          <div class="error error--inline" v-show="errorsByInput.worker">
            {{ errorsByInput.worker }}
          </div>
        </div>

        <div class="section">
          <label class="label" for="production-order">
            Töökeskus <span class="required">*</span>
          </label>

          <v-select
            v-model="selectedWorkstation"
            :options="workstations"
            placeholder="Palun vali..."
            @input="validate"
          >
            <div slot="no-options">Töökeskust ei leitud.</div>
          </v-select>

          <div class="error error--inline" v-show="errorsByInput.workstation">
            {{ errorsByInput.workstation }}
          </div>
        </div>

        <div class="section">
          <label class="label" for="production-order">
            Kuupäev <span class="required">*</span>
          </label>

          <date-picker
            v-model="startDateStr"
            :dayStr="datepickerOpts.dayStr"
            :monthStr="datepickerOpts.monthStr"
            :btnStr="datepickerOpts.btnStr"
            :max="maxDatepickerDate"
            :placeholder="datepickerOpts.placeholder"
            :firstDayOfWeek="datepickerOpts.firstDayOfWeek"
            @input="validate"
          />

          <div class="error error--inline" v-show="errorsByInput.startDate">
            {{ errorsByInput.startDate }}
          </div>
        </div>

        <div class="section">
          <div class="section__col">
            <label class="label" for="production-order">
              Alguskellaaeg <span class="required">*</span>
            </label>

            <input
              v-model="startTimeStr"
              placeholder="HH:MM"
              maxlength="5"
              @keyup="validate"
            />

            <div class="error error--inline" v-show="errorsByInput.startTime">
              {{ errorsByInput.startTime }}
            </div>
          </div>
          <div class="section__col">
            <label class="label" for="production-order"> Lõppkellaaeg </label>

            <input
              v-model="endTimeStr"
              placeholder="HH:MM"
              maxlength="5"
              @keyup="validate"
            />

            <div class="error error--inline" v-show="errorsByInput.endTime">
              {{ errorsByInput.endTime }}
            </div>
          </div>
        </div>

        <div class="error" v-show="errors.length">
          {{ errors.join(" ") }}
        </div>
      </form>
    </section>

    <footer>
      <button class="button button--light" @click="$emit('close')">Sulge</button>
      <button
        class="button save"
        @click="save"
        :disabled="!isValid || isProcessingForm || isLoadingData"
      >
        <span v-if="!isProcessingForm && !isLoadingData">Salvesta muudatused</span>
        <span v-else><i class="spinner fal fa-spinner"></i> Palun oota...</span>
      </button>
    </footer>
  </div>
</template>

<script>
const moment = require("moment-timezone");
import TimerMixin from "./TimerMixin";

export default {
  mixins: [TimerMixin],
  props: ["id"],
  data: () => ({
    datepickerOpts: null,
    maxDatepickerDate: null,

    startDateStr: "",
    startTimeStr: "",
    endTimeStr: "",

    isValid: false,
    isLoadingData: true,
    isProcessingForm: false,

    errorsByInput: {},
  }),
  created() {
    this.datepickerOpts = this.appConfig.datepicker;
    this.maxDatepickerDate = moment().format("YYYY-MM-DD");
  },
  methods: {
    getTimer() {
      const url = this.api.$timerGet.replace(":id", this.id);

      this.$http
        .get(url)
        .then((res) => {
          const data = res.data;

          this.selectedProductionOrder = {
            id: data.productionOrder.productionOrderId.id,
            label: `${data.productionOrder.productionOrderNumber} - ${data.orderTitle}`,
          };

          this.selectedWorker = {
            id: data.worker.workerId,
            label: data.worker.fullName,
          };

          this.selectedWorkstation = {
            id: data.workstation.workstationId,
            label: data.workstation.title,
          };

          let startDt = moment.utc(data.startDt).tz(this.appConfig.timezone);
          this.startDateStr = startDt.format("YYYY-MM-DD");
          this.startTimeStr = startDt.format("HH:mm");

          if (data.endDt) {
            let endDt = moment.utc(data.endDt).tz(this.appConfig.timezone);
            this.endTimeStr = endDt.format("HH:mm");
          }

          this.validate();
        })
        .catch(this.handleHttpError)
        .finally(() => {
          this.isLoadingData = false;
        });
    },
    onProductionOrderChanged(e) {
      this.onSelectedOrderChanged(e);
      this.validate();
    },
    validate(e) {
      this.errors = [];
      this.errorsByInput = {};

      if (!this.selectedProductionOrder) {
        this.errorsByInput["productionOrder"] = "Palun vali tellimus.";
      }
      if (!this.selectedWorker) {
        this.errorsByInput["worker"] = "Palun vali tööline.";
      }
      if (!this.selectedWorkstation) {
        this.errorsByInput["workstation"] = "Palun vali töökeskus.";
      }

      if (!this.startDateStr) {
        this.errorsByInput["startDate"] = "Palun vali kuupäev.";
      }

      const timeRegex = /([01]\d|2[0-3]):[0-5]\d/;
      if (!this.startTimeStr || !timeRegex.test(this.startTimeStr)) {
        this.errorsByInput["startTime"] =
          "Palun sisesta kellaaeg formaadis HH:MM (nt 09:45).";
      }

      if (this.endTimeStr && !timeRegex.test(this.endTimeStr)) {
        this.errorsByInput["endTime"] =
          "Palun sisesta kellaaeg formaadis HH:MM (nt 09:45).";
      }

      this.isValid = !Object.keys(this.errorsByInput).length;

      // Submit if enter is pressed
      if (e && e.keyCode === 13) {
        this.save();
      }
    },
    save() {
      if (!this.isValid) {
        return;
      }

      this.isProcessingForm = true;
      this.errors = [];
      this.errorsByInput = {};

      let startDt = new Date(this.startDateStr + " " + this.startTimeStr);

      let endDt = null;
      if (this.endTimeStr) {
        endDt = new Date(this.startDateStr + " " + this.endTimeStr);
      }

      const data = {
        productionOrderId: this.selectedProductionOrder.id,
        workerId: this.selectedWorker.id,
        workstationId: this.selectedWorkstation.id,
        startDt: startDt,
        endDt: endDt,
      };

      const url = this.api.$timerEdit.replace(":id", this.id);

      this.$http
        .post(url, data)
        .then(this.handleHttpPostSuccess)
        .catch(this.handleHttpError)
        .finally(() => (this.isProcessingForm = false));
    },
    handleHttpPostSuccess() {
      this.$emit("close", { isChanged: true });
    },

    handleHttpError(e) {
      const res = e.response ? e.response.data : {};

      if (!res.messages) {
        res.messages = [];

        if (res.message) {
          res.messages.push(res.message);
        }
      }

      let errorMessages = res.messages;
      errorMessages.unshift("Salvestamine ei õnnestunud.");

      this.errors = errorMessages;
    },
  },
  mounted() {
    if (!this.id) {
      this.isLoadingData = false;
      this.validate();
    } else {
      this.getTimer();
    }
  },
};
</script>

<style lang="scss">
.timer-edit {
  .section__col {
    position: relative;
    width: 50%;
    float: left;
    margin-bottom: 15px;

    &:first-child {
      padding-right: 10px;
    }

    &:last-child {
      padding-left: 10px;
    }

    .error {
      bottom: -40px;
    }
  }
}
</style>
