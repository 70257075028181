import axios from "axios";
import config from "./config";
import store from "./store";

export default () => {
    const instance = axios.create({
        baseURL: config.$apiUrl,
        headers: {
            "Content-Type": "application/json"
        }
    });

    const setAuthHeader = (token) => {
        instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    };

    const removeAuthHeader = () => {
        delete instance.defaults.headers.common["Authorization"];
    };

    // Add auth header on page load if present 
    const token = store.getters.token;
    if (token) {
        setAuthHeader(token);
    }

    // Add or remove auth header when token changes
    store.watch(state => state.token, (newValue) => {
        if (newValue) {
            setAuthHeader(newValue)
        } else {
            removeAuthHeader();
        }
    });

    return instance;
};
