<template>
  <div class="product-detail__prop" v-bind:class="{ undefinable: isUndefinable }">
    <div
      v-if="isEditable"
      class="product-detail__label product-detail__label--editable"
      title="Muuda"
      @click="edit"
    >
      {{ meta.displayLabel || meta.label }} <i class="icon icon--edit fal fa-edit"></i>
    </div>

    <div v-else class="product-detail__label">
      {{ meta.displayLabel || meta.label }}
    </div>

    <div class="product-detail__val">
      <product-detail-image
        v-if="meta.formType === 'file'"
        :fileId="val ? val.fileId : null"
      ></product-detail-image>
      <span v-else>{{ displayVal }}</span>
    </div>
  </div>
</template>

<script>
import EditProductProp from "./EditProductProp";
import ProductDetailImage from "./ProductDetailImage";

export default {
  components: { ProductDetailImage },
  props: ["prop", "val", "meta", "productId"],
  data: () => ({
    isUndefinable: true,
    isEditable: false,
  }),
  created() {
    if (!this.meta) {
      alert(`Failis sections.json on tundmatu tooteväli "${this.prop}"!`);
    }

    this.updateComponentData();
  },
  methods: {
    updateComponentData() {
      this.isUndefinable = this.val === undefined;

      this.isEditable = this.meta.isEditable === true && this.isUndefinable === false;
    },
    onValueChanged(val) {
      this.$emit("onValueChanged", { prop: this.prop, val: val });
    },
    edit() {
      let opts = { height: "auto" };

      if (this.$isTablet) {
        opts.width = "75%";
      }

      this.$modal.show(
        EditProductProp,
        {
          id: this.productId,
          prop: this.prop,
          type: this.meta.formType,
          label: this.meta.label || this.meta.displayLabel,
          options: this.meta.options,
          currentVal: this.val,
          onValueChanged: this.onValueChanged
        },
        opts
      );
    },
  },
  computed: {
    displayVal() {
      if (this.isUndefinable) {
        // This value should never exist for this product
        return "–";
      }

      if (this.meta.formType === "bool") {
        if (this.val === null) {
          // To be defined
          return "x";
        }

        return this.val ? "Jah" : "Ei";
      }

      return this.val || "x";
    },
  },
  watch: {
    productId: "updateComponentData",
  },
};
</script>

<style lang="scss">
.product-detail {
  &__prop {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 15px 30px;
    padding-left: 0;
    // border-right: 1px dotted $border-color;
    // border-bottom: 1px dotted $border-color;
    box-sizing: border-box;

    &.undefinable {
      .product-detail {
        &__label,
        &__val {
          color: $border-color;
          cursor: default;
        }
      }
    }
  }

  .icon {
    &--edit {
      margin-left: 10px;
      font-size: 16px;
    }
  }

  &__label {
    margin-bottom: 5px;
    font-size: $font-size-text;
    color: $color-medium;
    text-transform: uppercase;
    font-weight: $font-weight-bold;
    letter-spacing: 0.5px;

    &--editable {
      cursor: pointer;
      transition: color 100ms ease-in-out;

      &:hover {
        color: $color-dark;
      }
    }
  }

  &__val {
    font-size: $font-size-emphasis;
  }
}

.tablet .product-detail {
  &__label {
    font-size: 1.3em;

    &--editable {
      .icon--edit {
        position: relative;
        top: -1px;
        margin-left: 5px;
      }
    }
  }
  &__val {
    font-size: 1.5em;
  }
}
</style>
