import Vue from "vue"
import VueRouter from "vue-router"

import store from "./store.js"

import ProductDetail from "./components/Product/Detail/ProductDetail"
import ExcelProductsExport from "./components/Product/Excel/ExcelProductsExport"
import ExcelProductsImport from "./components/Product/Excel/ExcelProductsImport"
import EditLogIndex from "./components/EditLog/EditLogIndex"
import Login from "./components/Auth/Login"
import RestoreProducts from "./components/Restore/RestoreProducts"
import WorkerList from "./components/Worker/WorkerList"
import WorkstationList from "./components/Workstation/WorkstationList"
import ProductionOrderList from "./components/ProductionOrder/ProductionOrderList"
import TimerList from "./components/Timer/TimerList"
import TimerSummary from "./components/Timer/TimerSummary"

Vue.use(VueRouter)

const loginPath = "/login";

export const router = new VueRouter({
    mode: "history",
    routes: [
        {
            name: "home",
            path: "/",
            meta: {
                title: "Tööaja arvestus",
                layout: "timer",
                allowedRoles: ["user", "admin"]
            }
        },
        {
            name: "login",
            path: loginPath,
            component: Login,
            meta: { layout: "guest" }
        },
        {
            name: "productList",
            path: "/products",
            meta: {
                title: "Tooteandmed",
                layout: "products",
                allowedRoles: ["user", "admin"]
            }
        },
        {
            name: "productDetail",
            path: "/product/:id",
            component: ProductDetail,
            props: true,
            meta: {
                title: "Tooteandmed",
                layout: "products",
                allowedRoles: ["user", "admin"]
            }
        },
        {
            name: "admin",
            path: "/admin",
            redirect: { name: "excelProductsExport" },
            meta: {
                title: "Toodete eksport",
                allowedRoles: ["admin"]
            }
        },
        {
            name: "excelProductsExport",
            path: "/admin/products/export",
            component: ExcelProductsExport,
            meta: {
                title: "Toodete eksport",
                layout: "admin",
                allowedRoles: ["admin"]
            }
        },
        {
            name: "excelProductsImport",
            path: "/admin/products/import",
            component: ExcelProductsImport,
            meta: {
                title: "Toodete import",
                layout: "admin",
                allowedRoles: ["admin"]
            }
        },
        {
            name: "editLog",
            path: "/admin/editlog",
            component: EditLogIndex,
            meta: {
                title: "Muudatuste logi",
                layout: "admin",
                allowedRoles: ["admin"]
            }
        },
        {
            name: "restoreProducts",
            path: "/admin/restore/products",
            component: RestoreProducts,
            meta: {
                layout: "admin",
                allowedRoles: ["admin"]
            }
        },
        {
            name: "workerList",
            path: "/admin/workers",
            component: WorkerList,
            meta: {
                title: "Töölised",
                layout: "admin",
                allowedRoles: ["admin"]
            }
        },
        {
            name: "workstationList",
            path: "/admin/worsktations",
            component: WorkstationList,
            meta: {
                title: "Töökeskused",
                layout: "admin",
                allowedRoles: ["admin"]
            }
        },
        {
            name: "productionOrderList",
            path: "/admin/production-orders",
            component: ProductionOrderList,
            meta: {
                title: "Tootmistellimused",
                layout: "admin",
                allowedRoles: ["admin"]
            }
        },
        {
            name: "timerList",
            path: "/admin/timers/log",
            component: TimerList,
            meta: {
                title: "Ajaarvestuse logid",
                layout: "admin",
                allowedRoles: ["admin"]
            }
        },
        {
            name: "timerSummary",
            path: "/admin/timers/summary",
            component: TimerSummary,
            meta: {
                title: "Tellimuse kokkuvõte",
                layout: "admin",
                allowedRoles: ["admin"]
            }
        },
    ]
});

router.beforeEach((to, from, next) => {
    document.title = to.meta.title || "Urminor";

    const allowedRoles = to.meta.allowedRoles || [];
    const isAuthRequired = allowedRoles.length > 0;

    const isLoggedIn = store.getters.isLoggedIn;
    const user = store.getters.user || {};

    if (isLoggedIn) {
        // If authed user visits login, redirect home
        if (to.path === loginPath && isLoggedIn) {
            return next({ name: "home" });
        }

        // If user does not have required role, redirect home
        if (!allowedRoles.includes(user.role)) {
            return next("/");
        }
    } else {
        if (isAuthRequired) {
            return next({ name: "login" });
        }
    }

    next();
});
