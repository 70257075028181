<template>
  <div class="workers admin-page">
    <div class="admin-page__header">
      <h1>Töölised</h1>
      <button class="button button--add" title="Lisa tööline" @click="editItem(null)">
        <i class="fal fa-plus-circle"></i> Lisa uus
      </button>
    </div>

    <div class="datatable">
      <i v-if="isLoading" class="spinner fal fa-spinner"></i>

      <table v-else class="table">
        <thead>
          <th>Eesnimi</th>
          <th>Perekonnanimi</th>
          <th>Halda</th>
        </thead>
        <tbody>
          <tr v-for="worker in workers" :key="worker.id">
            <td>{{ worker.firstName }}</td>
            <td>{{ worker.lastName }}</td>
            <td class="actions">
              <button
                class="button button--xs button--light"
                title="Muuda"
                @click="editItem(worker.id)"
              >
                <i class="fal fa-pencil-alt"></i>
              </button>
              <button
                class="button button--xs button--accent"
                title="Kustuta"
                @click="deleteItem(worker.id)"
              >
                <i class="fal fa-trash-alt"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import EditWorker from "./EditWorker";

export default {
  data: () => ({
    isLoading: true,
    workers: [],
  }),

  created() {
    this.fetchWorkers();
  },

  methods: {
    fetchWorkers() {
      this.isLoading = true;

      this.$http
        .get(this.api.$workerList)
        .then((result) => {
          this.workers = result.data;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    editItem(id) {
      this.$modal.show(
        EditWorker,
        {
          id: id,
          onChanged: this.fetchWorkers,
        },
        { height: "auto" },
        {
          "before-close": this.onEditModalClosed,
        }
      );
    },
    onEditModalClosed(closeEvent) {
      // Reload list if changes were made
      if (closeEvent.params && closeEvent.params.isChanged === true) {
        this.fetchWorkers();
      }
    },
    deleteItem(id) {
      if (!confirm("Kas oled kindel, et tahad töölise kustutada?")) {
        return;
      }

      const url = this.api.$workerRemove.replace(":id", id);

      this.$http
        .post(url)
        .then(this.fetchWorkers)
        .catch((e) => {
          alert("Kustutamine ebaõnnestus.");
          console.log(e);
        });
    },
  },
};
</script>

<style lang="scss">
.admin-page {
  &__header {
    display: flex;
    align-items: center;

    button {
      margin-left: 20px;
    }
  }

  .datatable {
    margin-top: 30px;

    table {
      width: auto;
    }

    .actions {
      button {
        margin-right: 5px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}
</style>
