<template>
  <div class="database-restore">
    <h1>Taasta tooted JSON-failist</h1>
    <p>Laadi üles JSON-fail, mille sisuks on array toodetega.</p>

    <div class="database-restore__file">
      <file-input
        id="restore-file"
        acceptedMimesList="application/json"
        @fileSelected="onFileSelected"
        @fileReset="reset"
      />
    </div>

    <br />

    <button class="button" v-if="file && !isUploadInProgress" @click="upload">
      Taasta tooted
    </button>

    <div v-if="isUploadInProgress" class="database-restore__success">Palun oota...</div>

    <div v-if="errorMessage" class="database-restore__error">
      {{ errorMessage }}
    </div>
    <div v-else-if="isSuccess" class="database-restore__success">
      <p>{{ successMessage }}</p>
    </div>
  </div>
</template>

<script>
import config from "../../config";
import FileInput from "../Shared/FileInput";

export default {
  components: { FileInput },
  data: () => ({
    file: null,
    isUploadInProgress: false,
    isSuccess: false,
    errorMessage: "",
    successMessage: "",
  }),
  methods: {
    onFileSelected(file) {
      this.file = file;
      this.isSuccess = false;
      this.errorMessage = "";
    },
    reset() {
      this.isUploadInProgress = false;
      this.file = null;
    },
    upload() {
      this.isUploadInProgress = true;

      let formData = new FormData();
      formData.append("file", this.file);

      this.$http
        .post(config.$apiEndpoints.$restoreProducts, formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((res) => {
          this.isSuccess = true;
          this.successMessage = `Toodete taastamine õnnestus! Taastati ${res.data.numberOfProductsRestored} toodet.`;
        })
        .catch((e) => {
          const res = e.response ? e.response.data : {};

          if (!res.messages) {
            res.messages = [];

            if (res.message) {
              res.messages.push(res.message);
            }
          }

          let errorMessages = res.messages;
          errorMessages.unshift("Toodete taastamine ei õnnestunud.");

          this.errorMessage = errorMessages.join(" ");
        })
        .finally(this.reset);
    },
  },
};
</script>

<style lang="scss">
.database-restore {
  &__error,
  &__success {
    padding: 15px 0;
    font-size: 16px;
    font-style: italic;
  }
  &__error {
    color: $color-accent;
  }

  &__success {
    color: green;
  }

  &__file {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  &__filename {
    margin: 0 15px;
    font-style: italic;
  }

  &__clear {
    font-size: 20px;
    cursor: pointer;
  }

  &__submit {
    margin-top: 15px;
  }

  &__link {
    text-decoration: underline;
    cursor: pointer;
  }
}
</style>
