<template>
  <div class="edit-product modal" :class="{ 'modal--tablet': $isTablet }">
    <header>
      <h1>Muuda</h1>
      <i
        class="icon icon--close fal fa-times-circle"
        @click="$emit('close')"
        title="Sulge"
      ></i>
    </header>

    <section>
      <form autocomplete="off" v-on:submit.prevent="save">
        <div class="section">
          <label class="product-detail__label">{{ label }}</label>

          <div
            class="product-detail__val"
            ref="currentVal"
            v-bind:class="{
              expanded: isCurrentValExpanded,
              overflow: currentValHasOverflow,
            }"
          >
            <span v-if="this.type === 'bool'">{{ boolVal }}</span>
            <div v-else-if="this.type === 'file'">
              <div v-if="currentVal && currentVal.fileId">
                <p>
                  <i>{{ label }} on olemas.</i>
                  <span class="edit-product__remove-file" @click="removeFile">
                    Eemalda tootelt {{ label.toLowerCase() }}
                  </span>
                </p>
              </div>
              <i v-else>{{ label }} puudub.</i>
            </div>
            <span v-else>{{ currentVal || "x" }}</span>
          </div>

          <div
            class="edit-product__toggle"
            v-if="currentValHasOverflow"
            @click="isCurrentValExpanded = !isCurrentValExpanded"
          >
            <span v-if="!isCurrentValExpanded"
              ><i class="fal fa-chevron-down"></i> Kuva kogu tekst</span
            >
            <span v-else><i class="fal fa-chevron-up"></i> Minimeeri tekst</span>
          </div>
        </div>

        <div class="section">
          <label class="product-detail__label" for="val">Uus väärtus:</label>

          <div v-if="this.type === 'bool'" class="edit-product__radios">
            <label>
              <input
                type="radio"
                name="val"
                v-bind:value="true"
                v-model="newVal"
                v-on:change="validateNewVal"
              />
              Jah
            </label>

            <label>
              <input
                type="radio"
                name="val"
                v-bind:value="false"
                v-model="newVal"
                v-on:change="validateNewVal"
              />
              Ei
            </label>
          </div>

          <div v-else-if="this.type === 'select'">
            <v-select
              :value="newVal"
              :taggable="true"
              :filterable="false"
              :clearable="false"
              @option:selecting="onDropdownItemSelected"
              :options="options || []"
            />
          </div>

          <textarea
            v-else-if="this.type === 'textarea'"
            id="val"
            name="val"
            maxlength="5000"
            ref="val"
            @input="setNewVal"
          ></textarea>

          <file-input
            v-else-if="this.type === 'file'"
            id="product-image"
            v-on:fileSelected="onFileSelected"
            v-on:fileReset="onFileReset"
          />

          <input
            v-else
            :type="this.type"
            id="val"
            name="val"
            maxlength="500"
            ref="val"
            @input="setNewVal"
          />
        </div>

        <div class="error" v-show="errorMessage">
          {{ errorMessage }}
        </div>
      </form>
    </section>

    <footer>
      <button class="button button--light" @click="$emit('close')">Sulge</button>
      <button class="button save" @click="save" :disabled="!isValid || isLoading">
        <span v-if="!isLoading">Salvesta muudatused</span>
        <span v-else><i class="spinner fal fa-spinner"></i> Palun oota...</span>
      </button>
    </footer>
  </div>
</template>

<script>
import FileInput from "../../Shared/FileInput";

export default {
  components: { FileInput },
  props: ["id", "prop", "type", "label", "options", "currentVal", "onValueChanged"],
  data: () => ({
    currentValHasOverflow: false,
    isCurrentValExpanded: false,
    file: null,
    newVal: null,
    isValid: false,
    isLoading: false,
    errorMessage: "",
  }),
  methods: {
    /**
     * Hack to get case-insensitive tags with vue-select:
     * In case a user-created tag is selected,
     * check if an original option matches the tag (case-insensitively).
     * If so, select the existing option instead of the tag.
     */
    onDropdownItemSelected(val) {
      let selectedVal = (val || "").trim();

      for (let i = 0, len = this.options.length; i < len; i++) {
        if (selectedVal.toLowerCase() === this.options[i].toLowerCase()) {
          selectedVal = this.options[i];
          break;
        }
      }

      this.newVal = selectedVal;
      this.validateNewVal();
    },

    /**
     * v-model does not update on each keystroke on Galaxy Tab A7;
     * must update model manually using @input
     * https://github.com/vuejs/vue/issues/9299
     */
    setNewVal(e) {
      this.newVal = e.target.value;
      this.validateNewVal(e);
    },

    validateNewVal(e) {
      // Clear error message unless Enter is pressed
      if (e && e.keyCode !== 13) {
        this.errorMessage = "";
      }

      let isEmpty = this.newVal === "";
      let isSameAsCurrent = this.newVal == this.currentVal;
      let isNan = false;

      switch (this.type) {
        case "number":
          isNan =
            this.type === "number" && (this.newVal === null || Number.isNaN(this.newVal));
          break;

        case "file":
          isSameAsCurrent = false;
          isEmpty = !this.file;
          break;
      }

      if (isEmpty) {
        this.errorMessage = "Salvestamiseks sisesta uus väärtus.";
      } else if (isNan) {
        this.errorMessage = "Palun sisesta number.";
      } else if (isSameAsCurrent) {
        this.errorMessage = "Salvestamiseks sisesta uus väärtus, mis erineb praegusest.";
      } else {
        this.errorMessage = "";
      }

      this.isValid = !isEmpty && !isSameAsCurrent;
    },

    save() {
      if (typeof this.newVal === "string") {
        this.newVal = this.newVal.trim();
      }

      this.validateNewVal();

      if (!this.isValid) {
        return;
      }

      this.isLoading = true;
      this.errorMessage = "";

      if (this.type === "file") {
        this.saveFile();
      } else {
        this.saveValue();
      }
    },

    saveFile() {
      let formData = new FormData();
      formData.append("file", this.file);

      let url = this.api.$productFileUpload
        .replace(":id", this.id)
        .replace(":prop", this.prop);

      this.$http
        .post(url, formData, { headers: { "Content-Type": "multipart/form-data" } })
        .then(this.handleHttpSuccess)
        .catch((e) => {
          this.handleHttpError(e), (this.isValid = true);
        })
        .finally(() => (this.isLoading = false));
    },

    saveValue() {
      const data = {
        prop: this.prop,
        val: this.newVal,
      };

      let url = this.api.$productEdit.replace(":id", this.id);

      this.$http
        .post(url, data)
        .then(this.handleHttpSuccess)
        .catch(this.handleHttpError)
        .finally(() => (this.isLoading = false));
    },

    removeFile() {
      if (!this.currentVal || !this.currentVal.fileId) {
        return;
      }

      if (!confirm("Kas oled kindel, et soovid foto tootelt eemaldada?")) {
        return;
      }

      let url = this.api.$productFileRemove
        .replace(":id", this.id)
        .replace(":prop", this.prop);

      this.$http.post(url).then(this.handleHttpSuccess).catch(this.handleHttpError);
    },

    handleHttpSuccess(res) {
      this.onValueChanged(res.data[this.prop]);
      this.$emit("close");
    },

    handleHttpError(e) {
      const res = e.response ? e.response.data : {};

      if (!res.messages) {
        res.messages = [];

        if (res.message) {
          res.messages.push(res.message);
        }
      }

      let errorMessages = res.messages;
      errorMessages.unshift("Salvestamine ei õnnestunud.");

      this.errorMessage = errorMessages.join(" ");
    },

    onFileSelected(file) {
      this.file = file;
      this.validateNewVal();
    },
    onFileReset() {
      this.file = null;
      this.validateNewVal();
    },
  },
  mounted() {
    if (this.$refs.val) {
      this.$refs.val.focus();
    }
    if (this.$refs.currentVal) {
      this.currentValHasOverflow =
        this.type !== "file" &&
        this.$refs.currentVal.scrollHeight > this.$refs.currentVal.clientHeight;
    }
  },
  computed: {
    boolVal() {
      if (this.currentVal === null) {
        return "x";
      }

      return this.currentVal ? "Jah" : "Ei";
    },
  },
};
</script>

<style lang="scss">
.edit-product {
  &__radios {
    label {
      font-size: 16px;
      margin: 10px 0;
      cursor: pointer;
    }

    input {
      position: relative;
      top: 1px;
      width: initial;
      margin-right: 5px;
    }
  }

  .icon {
    &--close {
      font-size: 20px;
      cursor: pointer;
    }
  }

  &__toggle {
    margin: 10px 0;
    padding: 3px 13px;
    font-size: 14px;
    color: $color-dark;
    background: lighten($border-color, 8%);
    float: right;
    border-radius: 4px;
    cursor: pointer;

    i {
      font-size: 12px;
      margin-right: 5px;
    }
  }

  &__remove-file {
    padding: 3px 10px;
    margin-left: 10px;
    font-size: 14px;
    color: $color-accent;
    background: lighten($border-color, 8%);
    font-style: italic;
    border-radius: 3px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;

    &:hover {
      background: $border-color;
      color: darken($color-accent, 10%);
    }

    i {
      font-size: 12px;
    }
  }
}

.edit-product.modal--tablet {
  header {
    h1 {
      font-size: 1.8em;
    }
  }

  section {
    padding-top: 50px;

    .error {
      font-size: 1.2em;
    }
  }

  .section {
    margin-bottom: 25px;
  }

  .product-detail {
    &__label {
      font-size: 1.3em;
    }
    &__val {
      font-size: 1.3em;
    }
  }

  input[type="text"],
  input[type="number"],
  textarea {
    font-size: 1.4em;
  }

  input[type="text"],
  input[type="number"] {
    padding: 10px 25px;
  }

  .edit-product__radios {
    label {
      font-size: 1.3em;
      color: $text-color;
      font-weight: $font-weight-light;
      text-transform: none;
    }

    input {
      position: relative;
      top: 5px;
      width: 25px;
      height: 25px;
    }
  }

  .icon {
    &--close {
      font-size: 1.6em;
    }
  }

  .edit-product__toggle {
    font-size: 1.2em;
    padding: 10px 20px;

    i {
      font-size: 1em;
    }
  }

  &__remove-file {
    i {
    }
  }

  footer {
    .button {
      font-size: 1.4em;
      padding: 12px 30px;
    }
  }

  .vs {
    &__search {
      padding-top: 5px !important;
      padding-bottom: 5px !important;
      font-size: 1.2em;
    }

    &__dropdown-menu {
      padding: 0;
    }

    &__dropdown-option {
      padding-top: 10px;
      padding-bottom: 10px;
      border-bottom: $border;
      background-color: lighten($border-color, 10%);
      font-size: 1.2em;

      &:last-child {
        border-bottom: none;
      }

      &--highlight {
        background-color: $color-medium;
      }
    }

    &__clear {
      font-size: 1.1em;
      padding-left: 15px;
      padding-right: 15px;
    }

    &__open-indicator {
      transform: scale(1.2);
      padding-left: 15px;
      padding-right: 15px;
    }

    &__selected {
      font-size: 1.3em;
    }
  }
}
</style>
