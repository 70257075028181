<template>
  <div>
    <h2
      class="product-detail__header"
      v-if="section.title"
      v-bind:class="{ collapsed: isCollapsed }"
      @click="toggle(section.name)"
    >
      <i class="fal fa-chevron-down"></i> {{ section.title }}
    </h2>

    <slide-up-down class="product-detail__section" :duration="200" :active="!isCollapsed">
      <div
        v-for="(subsection, subsectionIndex) in section.subsections"
        v-bind:key="subsectionIndex"
      >
        <div
          v-for="(col, colIndex) in subsection.cols"
          v-bind:key="colIndex"
          class="product-detail__col"
          v-bind:class="{ 'full-width': subsection.isFullWidth }"
        >
          <div v-for="(item, itemIndex) in col" v-bind:key="itemIndex">
            <div v-if="item.type === 'hr'" class="product-detail__hr"></div>

            <p v-else-if="item.type === 'text'" class="product-detail__note">
              <i class="fal fa-info-circle"></i> {{ item.value }}
            </p>

            <product-detail-prop
              v-else-if="item.type === 'prop'"
              v-bind:productId="product.id"
              v-bind:prop="item.value"
              v-bind:val="product[item.value]"
              v-bind:meta="props[item.value]"
              v-on:onValueChanged="onValueChanged"
            >
            </product-detail-prop>
          </div>
          <!-- /Prop -->
        </div>
        <!-- /Col -->
      </div>
      <!-- /Row -->
    </slide-up-down>
  </div>
</template>

<script>
import ProductDetailProp from "./ProductDetailProp";

export default {
  components: {
    ProductDetailProp,
  },

  props: ["section", "product", "props", "isCollapsed"],

  methods: {
    toggle() {
      this.$emit("toggled");
    },
    onValueChanged(data) {
      this.product[data.prop] = data.val;
    },
  },
};
</script>

<style lang="scss">
.product-detail {
  &__section {
    clear: both;
    margin-bottom: 30px;
    padding: 0 $sidebar-padding;

    &::after {
      content: "";
      display: block;
      height: 0;
      clear: both;
    }
  }

  &__header {
    margin: 0 0 1px 0;
    padding: 10px;
    font-size: 16px;
    border-top: $border;
    border-bottom: $border;
    letter-spacing: 1px;
    cursor: pointer;

    i {
      margin: 0 10px 0 20px;
      transform: rotate(0);
      transition: transform 150ms ease-in;
    }

    &.collapsed {
      i {
        transform: rotate(-180deg);
      }
    }
  }

  &__col {
    width: 50%;
    float: left;
    padding-right: 30px;
    box-sizing: border-box;

    &.full-width {
      width: 100%;
      float: none;
    }
  }

  @media (max-width: 1000px) {
    &__col {
      width: 100%;
      padding-right: 0;
    }
  }

  &__hr {
    width: 100%;
    height: 0;
    border-bottom: 1px dotted $border-color;
    margin: 38px 0;
  }

  &__note {
    color: $color-dark;

    i {
      position: relative;
      top: 2px;
      font-size: 18px;
      margin-right: 3px;
    }
  }
}

.tablet .product-detail {
  &__header {
    font-size: 1.3em;
  }
}
</style>
