<template>
  <div class="app app--admin">
    <div class="app__sidebar">
      <div class="app__sidebar__section">
        <h1>Tooted</h1>
        <ul>
          <li>
            <router-link :to="{ name: 'excelProductsExport' }">
              <i class="fad fa-file-export"></i> Eksport
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'excelProductsImport' }">
              <i class="fad fa-file-import"></i> Import
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'editLog' }">
              <i class="fad fa-clipboard-list"></i> Muudatuste logi
            </router-link>
          </li>
        </ul>
      </div>
      <div class="app__sidebar__section">
        <h1>Tellimused</h1>
        <ul>
          <li>
            <router-link :to="{ name: 'productionOrderList' }">
              <i class="fad fa-list-alt"></i> Tellimuste nimekiri
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'timerList' }">
              <i class="fad fa-clock"></i> Ajaarvestuse kanded
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'timerSummary' }">
              <i class="fad fa-clipboard-check"></i> Tellimuse kokkuvõte
            </router-link>
          </li>

        </ul>
      </div>
      <div class="app__sidebar__section">
        <h1>Ressursid</h1>
        <ul>
          <li>
            <router-link :to="{ name: 'workerList' }">
              <i class="fad fa-users"></i> Töölised
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'workstationList' }">
              <i class="fad fa-hammer"></i> Töökeskused
            </router-link>
          </li>
        </ul>
      </div>
    </div>

    <div class="app__content">
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  components: {},
};
</script>

<style lang="scss">
.app {
  &--admin {
    .app__sidebar,
    .app__content {
      padding-top: 60px;
    }

    .app__sidebar {
      background: $color-dark;
      color: $background-color;
      overflow-y: auto;
      border: none;

      &__section {
        margin-bottom: 30px;
      }

      h1 {
        font-size: 16px;
        margin-left: 30px;
        letter-spacing: 2px;
        color: $color-medium;
      }

      ul {
        margin: 0;
        padding: 0;
      }

      li {
        position: relative;
        margin: 0;
        padding: 0;
        list-style-type: none;
        font-weight: $font-weight-medium;
        cursor: pointer;

        a {
          display: block;
          padding: 15px 15px 15px 70px;
          color: $background-color;
        }

        &:hover {
          background: $border-color;
        }

        i {
          position: absolute;
          top: 18px;
          left: 30px;
        }

        .fa-clipboard-list {
          font-size: 20px;
          left: 32px;
        }
      }

      .router-link-active {
        background: $color-medium;
      }
    }

    .app__content {
      padding-left: $sidebar-padding;

      h1 {
        margin: 0;
        font-size: 30px;
        font-weight: $font-weight-medium;
        letter-spacing: 1px;
      }
    }
  }
}

.tablet .app--admin {
  .app__content {
    margin-top: 50px;
  }
}
</style>
