<template>
  <div class="excel__import modal">
    <header>
      <h1>Tellimuste import Excelist</h1>
      <i
        class="icon icon--close fal fa-times-circle"
        @click="$emit('close')"
        title="Sulge"
      ></i>
    </header>

    <section>
      <p>Laadi üles XLSX-fail ja selle esimene leht sisestatakse rakenduse andmebaasi.</p>
      <p>NB! Kui mõnel faili lehel on peal tulbafilter, ei õnnestu faili importida.</p>

      <div class="excel__import__file">
        <file-input
          id="import-file"
          acceptedMimesList="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          @fileSelected="onFileSelected"
          @fileReset="reset"
        />
      </div>

      <div v-if="isUploadInProgress" class="excel__import__success">Palun oota...</div>

      <div v-if="errorMessage" class="excel__import__error">
        {{ errorMessage }}
      </div>
      <div v-else-if="isSuccess" class="excel__import__success">
        <p>{{ successMessage }}</p>
        <a class="excel__import__link" v-if="importLogFileId" @click="downloadImportLog">
          <span v-if="!isLoadingLogFile">Laadi alla impordilogi</span>
          <span v-else>Laadin, palun oota...</span>
        </a>
      </div>
    </section>

    <footer>
      <button class="button button--light" @click="$emit('close')">Sulge</button>

      <button
        class="button"
        :disabled="!file || isUploadInProgress"
        @click="upload"
      >
        Impordi tellimused
      </button>
    </footer>
  </div>
</template>

<script>
import config from "../../config";
import FileInput from "../Shared/FileInput";

export default {
  components: { FileInput },
  data: () => ({
    file: null,
    isUploadInProgress: false,
    isSuccess: false,
    errorMessage: "",
    successMessage: "",
    importLogFileId: null,
    importLogFileName: null,
    isLoadingLogFile: false
  }),
  methods: {
    onFileSelected(file) {
      this.file = file;
      this.isSuccess = false;
      this.errorMessage = "";
    },
    reset() {
      this.isUploadInProgress = false;
      this.file = null;
    },
    upload() {
      this.isUploadInProgress = true;

      let formData = new FormData();
      formData.append("file", this.file);

      this.$http
        .post(config.$apiEndpoints.$excelProductionOrdersImport, formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((res) => {
          this.isSuccess = true;
          this.importLogFileId = res.data.logFile.fileId;
          this.importLogFileName = res.data.logFile.fileName;
          this.successMessage = `Import õnnestus! Imporditi ${res.data.numberOfOrdersImported} tellimust.`;
        })
        .catch((e) => {
          const res = e.response ? e.response.data : {};

          if (!res.messages) {
            res.messages = [];

            if (res.message) {
              res.messages.push(res.message);
            }
          }

          let errorMessages = res.messages;
          errorMessages.unshift("Import ei õnnestunud.");

          this.errorMessage = errorMessages.join(" ");
        })
        .finally(this.reset);
    },
    downloadImportLog() {
      if (!this.importLogFileId) {
        return;
      }

      this.isLoadingLogFile = true;

      let url = config.$apiEndpoints.$excelProductionOrdersImportFile.replace(
        ":id",
        this.importLogFileId
      );

      this.$http
        .get(url, {
          responseType: "arraybuffer",
        })
        .then((res) => {
          let fileUrl = window.URL.createObjectURL(new Blob([res.data]), {
            type: this.mimeType,
          });
          let fileLink = document.createElement("a");

          fileLink.href = fileUrl;
          fileLink.setAttribute("download", this.importLogFileName);

          document.body.appendChild(fileLink);

          fileLink.click();
        })
        .catch(() => alert("Faili ei õnnestunud alla laadida."))
        .finally(() => (this.isLoadingLogFile = false));
    },
  },
};
</script>
