<template>
  <div class="actions">
    <button class="button button--xs button--light" @click="editItem">
      <i class="fal fa-pencil-alt"></i>
    </button>
  </div>
</template>

<script>
import TimerEdit from "./TimerEdit";

export default {
  props: ["row"],
  data: () => ({
    id: null,
  }),
  methods: {
    editItem() {
      this.$modal.show(
        TimerEdit,
        {
          id: this.id,
        },
        { height: "auto" },
        {
          closed: this.onModalClosed,
        }
      );
    },
    onModalClosed(e) {
      this.$emit("eventEmitted", e);
    },
  },
  created() {
    this.id = this.row.id;
  },
};
</script>

<style lang="scss"></style>
