export default {
  $isProduction: process.env.NODE_ENV === "production",
  $apiUrl: process.env.VUE_APP_API_URL,
  $apiEndpoints: {
    // Auth
    $login: "/login",

    // Products
    $productListSearch: "/products/list/search/:term/:prop",
    $productDetail: "/products/detail/:id",
    $productEdit: "/products/edit/:id",
    $productFileUpload: "/product-files/upload/:id/:prop",
    $productFileGet: "/product-files/get/:id",
    $productFileRemove: "/product-files/remove/:id/:prop",
    $restoreProducts: "/restore/products",

    // Product import/export
    $excelProductsExport: "/excel-products/export",
    $excelProductsImport: "/excel-products/import",
    $excelProductsImportFile: "/excel-products/import-file/:id",

    // Edit logs
    $editLogList: "/edit-log/list",

    // Workers
    $workerList: "/workers/list",
    $workerAdd: "/workers/add",
    $workerGet: "/workers/get/:id",
    $workerEdit: "/workers/edit/:id",
    $workerRemove: "/workers/remove/:id",

    // Workstations
    $workstationList: "/workstations/list",
    $workstationAdd: "/workstations/add",
    $workstationGet: "/workstations/get/:id",
    $workstationEdit: "/workstations/edit/:id",
    $workstationRemove: "/workstations/remove/:id",

    // Production orders
    $productionOrderList: "/production-orders/list",
    $excelProductionOrdersExport: "/excel-orders/export",
    $excelProductionOrdersImport: "/excel-orders/import",
    $excelProductionOrdersImportFile: "/excel-orders/import-file/:id",

    // Timer
    $timerOptions: "/timer/options",
    $timerProductByOrderId: "/timer/product/:order-id",
    $timerStart: "/timer/start",
    $timerStop: "/timer/stop/:id",
    $timerGet: "/timer/get/:id",
    $timerList: "/timer/list",
    $timerEdit: "/timer/edit/:id",
    $timerSummary: "/timer/summary",

    // Timer export
    $excelProductionOrderTimersExport: "/excel-timers/export"
  },
  datepicker: {
    dayStr: ["P", "E", "T", "K", "N", "R", "L"],
    monthStr: ["jaan", "veeb", "märts", "apr", "mai", "juuni", "juuli",
      "aug", "sept", "okt", "nov", "dets"],
    timeStr: ["tund", "min", "sek"],
    btnStr: "OK",
    placeholder: "Palun vali aeg...",
    secondPlaceholder: "Palun vali lõpp...",
    firstDayOfWeek: 0
  },
  timezone: "Europe/Tallinn",
  chromeOnGalaxyTabRegex: "(X11; Linux x86_64)"
};
