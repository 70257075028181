<template>
  <div class="modal">
    <header>
      <h1 v-if="id">Muuda töölist</h1>
      <h1 v-else>Lisa tööline</h1>

      <i
        class="icon icon--close fal fa-times-circle"
        @click="$emit('close')"
        title="Sulge"
      ></i>
    </header>

    <section>
      <i v-if="isLoadingData" class="spinner fal fa-spinner"></i>

      <form v-else autocomplete="off" v-on:submit.prevent="save">
        <div class="section">
          <label class="label" for="first-name">
            Eesnimi <span class="required">*</span>
          </label>

          <input
            type="text"
            id="first-name"
            name="firstName"
            ref="firstName"
            maxlength="50"
            v-model="firstName"
            v-on:keyup="validate"
          />

          <div class="error error--inline" v-show="errors.firstName">
            {{ errors.firstName }}
          </div>
        </div>

        <div class="section">
          <label class="label" for="last-name">
            Perekonnanimi <span class="required">*</span>
          </label>

          <input
            type="text"
            id="last-name"
            name="lastName"
            maxlength="50"
            v-model="lastName"
            v-on:keyup="validate"
          />
          <div class="error error--inline" v-show="errors.lastName">
            {{ errors.lastName }}
          </div>
        </div>

        <div class="error" v-show="errors.general">
          {{ (errors.general || []).join(" ") }}
        </div>
      </form>
    </section>

    <footer>
      <button class="button button--light" @click="$emit('close')">Sulge</button>
      <button
        class="button save"
        @click="save"
        :disabled="!isValid || isProcessingForm || isLoadingData"
      >
        <span v-if="!isProcessingForm && !isLoadingData">Salvesta muudatused</span>
        <span v-else><i class="spinner fal fa-spinner"></i> Palun oota...</span>
      </button>
    </footer>
  </div>
</template>

<script>
export default {
  props: ["id"],
  data: () => ({
    firstName: "",
    lastName: "",
    isValid: false,
    isLoadingData: true,
    isProcessingForm: false,
    errors: {},
  }),
  methods: {
    focusFirstName() {
      if (this.$refs.firstName) {
        this.$refs.firstName.focus();
      }
    },
    getWorker() {
      const url = this.api.$workerGet.replace(":id", this.id);

      this.$http
        .get(url)
        .then((res) => {
          this.firstName = res.data.firstName;
          this.lastName = res.data.lastName;
          this.validate();
        })
        .catch(this.handleHttpError)
        .finally(() => {
          this.isLoadingData = false;
          this.$nextTick(this.focusFirstName);
        });
    },
    validate(e) {
      this.errors = {};

      if (!this.firstName.trim()) {
        this.errors.firstName = "Palun sisesta eesnimi.";
      }
      if (!this.lastName.trim()) {
        this.errors.lastName = "Palun sisesta perekonnanimi.";
      }

      this.isValid = !Object.keys(this.errors).length;

      // Submit if enter is pressed
      if (e && e.keyCode === 13) {
        this.save();
      }
    },
    save() {
      if (!this.isValid) {
        return;
      }

      this.isProcessingForm = true;
      this.errors = {};

      const data = {
        firstName: this.firstName,
        lastName: this.lastName,
      };

      let url = this.id
        ? this.api.$workerEdit.replace(":id", this.id)
        : this.api.$workerAdd;

      this.$http
        .post(url, data)
        .then(this.handleHttpPostSuccess)
        .catch(this.handleHttpError)
        .finally(() => (this.isProcessingForm = false));
    },
    handleHttpPostSuccess() {
      this.$emit("close", { isChanged: true });
    },

    handleHttpError(e) {
      const res = e.response ? e.response.data : {};

      if (!res.messages) {
        res.messages = [];

        if (res.message) {
          res.messages.push(res.message);
        }
      }

      let errorMessages = res.messages;
      errorMessages.unshift("Salvestamine ei õnnestunud.");

      this.errors.general = errorMessages;
    },
  },
  mounted() {
    if (!this.id) {
      this.isLoadingData = false;
      this.validate();
      this.$nextTick(this.focusFirstName);
    } else {
      this.getWorker();
    }
  },
};
</script>
