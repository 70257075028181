<template>
  <div class="search-input">
    <div class="icon icon--search">
      <i class="fal fa-search"></i>
    </div>
    <div class="icon icon--close" @click="reset" v-if="searchTerm">
      <i class="fal fa-times"></i>
    </div>
    <input
      type="text"
      class="input input--search"
      ref="searchTerm"
      :maxlength="maxlength"
      :placeholder="placeholder"
      @input="onTermChanged"
      @keyup.escape="reset"
      @blur="$emit('blur')"
    />
  </div>
</template>

<script>
export default {
  props: ["placeholder", "maxlength", "isFocused"],
  data: () => ({
    searchTerm: "",
  }),
  methods: {
    reset() {
      this.$refs.searchTerm.value = "";
      this.onTermChanged();
    },
    onTermChanged() {
      // Cannot use v-model as it is not updated
      // on Samsung Galaxy Tab with every keystroke
      this.searchTerm = this.$refs.searchTerm.value.trim();
      this.$emit("onTermChanged", this.searchTerm);
    },
    setFocus() {
      if (this.isFocused) {
        this.$refs.searchTerm.focus();
      }
    },
  },
  mounted() {
    this.setFocus();
  },
  watch: {
    isFocused: "setFocus",
  },
};
</script>

<style lang="scss">
.search-input {
  position: relative;

  .icon {
    position: absolute;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 43px;

    i {
      font-size: 16px;
    }

    &--search {
      left: 0;
      background: $color-dark;
      color: $background-color;
      border: 1px solid $color-dark;
    }

    &--close {
      top: 0;
      right: 0;
      cursor: pointer;
      z-index: 100;
    }
  }

  .input--search {
    width: 100%;
    padding: 10px 15px 10px 50px;
    font-family: $font-sans-serif;
    font-size: $font-size-text;
    box-sizing: border-box;
  }
}

.tablet .search-input {
  .input--search {
    padding-top: 14px;
    padding-bottom: 14px;
    padding-left: 65px;
    padding-right: 45px;
    font-size: 1.25em;
  }

  .icon {
    width: 56px;

    &--close {
      i {
        font-size: 1.2em;
      }
    }
  }
}
</style>
