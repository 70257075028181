<template>
  <div class="modal">
    <header>
      <h1 v-if="id">Muuda töökeskust</h1>
      <h1 v-else>Lisa töökeskus</h1>

      <i
        class="icon icon--close fal fa-times-circle"
        @click="$emit('close')"
        title="Sulge"
      ></i>
    </header>

    <section>
      <i v-if="isLoadingData" class="spinner fal fa-spinner"></i>

      <form v-else autocomplete="off" v-on:submit.prevent="save">
        <div class="section">
          <label class="label" for="title">
            Nimetus <span class="required">*</span>
          </label>

          <input
            type="text"
            id="title"
            name="title"
            ref="title"
            maxlength="100"
            v-model="title"
            v-on:keyup="validate"
          />

          <div class="error error--inline" v-show="errors.title">
            {{ errors.title }}
          </div>
        </div>

        <div class="error" v-show="errors.general">
          {{ (errors.general || []).join(" ") }}
        </div>
      </form>
    </section>

    <footer>
      <button class="button button--light" @click="$emit('close')">Sulge</button>
      <button
        class="button save"
        @click="save"
        :disabled="!isValid || isProcessingForm || isLoadingData"
      >
        <span v-if="!isProcessingForm && !isLoadingData">Salvesta muudatused</span>
        <span v-else><i class="spinner fal fa-spinner"></i> Palun oota...</span>
      </button>
    </footer>
  </div>
</template>

<script>
export default {
  props: ["id"],
  data: () => ({
    title: "",
    isValid: false,
    isLoadingData: true,
    isProcessingForm: false,
    errors: {},
  }),
  methods: {
    focusTitle() {
      if (this.$refs.title) {
        this.$refs.title.focus();
      }
    },
    getWorkstation() {
      const url = this.api.$workstationGet.replace(":id", this.id);

      this.$http
        .get(url)
        .then((res) => {
          this.title = res.data.title;
          this.validate();
        })
        .catch(this.handleHttpError)
        .finally(() => {
          this.isLoadingData = false;
          this.$nextTick(this.focusTitle);
        });
    },
    validate() {
      this.errors = {};

      if (!this.title.trim()) {
        this.errors.title = "Palun sisesta töökeskuse nimetus.";
      }

      this.isValid = !Object.keys(this.errors).length;
    },
    save() {
      if (!this.isValid) {
        return;
      }

      this.isProcessingForm = true;
      this.errors = {};

      const data = {
        title: this.title,
      };

      let url = this.id
        ? this.api.$workstationEdit.replace(":id", this.id)
        : this.api.$workstationAdd;

      this.$http
        .post(url, data)
        .then(this.handleHttpPostSuccess)
        .catch(this.handleHttpError)
        .finally(() => (this.isProcessingForm = false));
    },
    handleHttpPostSuccess() {
      this.$emit("close", { isChanged: true });
    },

    handleHttpError(e) {
      const res = e.response ? e.response.data : {};

      if (!res.messages) {
        res.messages = [];

        if (res.message) {
          res.messages.push(res.message);
        }
      }

      let errorMessages = res.messages;
      errorMessages.unshift("Salvestamine ei õnnestunud.");

      this.errors.general = errorMessages;
    },
  },
  mounted() {
    if (!this.id) {
      this.isLoadingData = false;
      this.validate();
      this.$nextTick(this.focusTitle);
    } else {
      this.getWorkstation();
    }
  },
};
</script>
